import { useState, useLayoutEffect } from 'react'
import { onValue, ref, getDatabase, push, set, remove } from 'firebase/database'
import { database } from '../configs/firebase'

export const usePlaces = () => {
  const [places, setplaces] = useState([])

  useLayoutEffect(() => {
    onValue(
      ref(database, 'lugares/'),
      (snapshot) => {
        const placesList = []
        snapshot.forEach((item) => {
          const placeItem = {
            id: item.key,
            camino: item.val().camino,
            distancia: item.val().distancia,
            nombre: item.val().nombre,
            observaciones: item.val().observaciones,
            tiempoDoble: item.val().tiempoDoble,
            tiempoCamion: item.val().tiempoCamion,
            tiempoTorton: item.val().tiempoTorton,
            zona: item.val().zona,
          }
          placesList.push(placeItem)
        })
        setplaces(placesList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])

  return [places]
}

export const addPlace = (
  nombre,
  distancia,
  zona,
  camino,
  tiempoDoble,
  tiempoCamion,
  tiempoTorton,
  observaciones
) => {
  const db = getDatabase()

  push(ref(db, 'lugares'), {
    nombre: nombre,
    distancia: distancia,
    zona: zona,
    camino: camino,
    tiempoDoble: tiempoDoble,
    tiempoCamion: tiempoCamion,
    tiempoTorton: tiempoTorton,
    observaciones: observaciones,
  })
}

export const updatePlace = (
  id,
  nombre,
  distancia,
  zona,
  camino,
  tiempoDoble,
  tiempoCamion,
  tiempoTorton,
  observaciones
) => {
  const db = getDatabase()

  set(ref(db, `lugares/${id}`), {
    nombre: nombre,
    distancia: distancia,
    zona: zona,
    camino: camino,
    tiempoDoble: tiempoDoble,
    tiempoCamion: tiempoCamion,
    tiempoTorton: tiempoTorton,
    observaciones: observaciones,
  })
}

export const deletePlace = (id) => {
  const db = getDatabase()

  remove(ref(db, `lugares/${id}`))
}

export const getPlace = (lugar) => {
  var placeItem = ''
  onValue(ref(database, 'lugares/'), (snapshot) => {
    snapshot.forEach((item) => {
      if (item.val().nombre === lugar) {
        placeItem = {
          camino: item.val().camino,
          distancia: item.val().distancia,
          tiempoDoble: item.val().tiempoDoble,
          tiempoCamion: item.val().tiempoCamion,
          tiempoTorton: item.val().tiempoTorton,
        }
      }
    })
  })
  return placeItem
}
