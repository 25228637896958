import jsPDF from 'jspdf'
import 'jspdf-autotable'

import logo from '../assets/bg-logo.webp'

import { onValue, ref } from 'firebase/database'
import { database } from '../configs/firebase'
import { fecha, formatDate, msToTime } from './datetime'

import { showTicketNumber } from '../helpers/cashFunctions'

export const generateCash = () => {
  let amountDay = 0
  let amount = []
  let amountList = []

  let transferDay = 0
  let transfer = []
  let transferList = []

  let servicesDay = 0
  let servicesAmount = []
  let servicesList = []

  onValue(ref(database, 'caja/'), (snapshot) => {
    snapshot.forEach((item) => {
      const serviceItem = [
        item.val().fecha,
        showTicketNumber(item.val().ticket),
        item.val().tipo_pago,
        '$ ' + item.val().importe,
      ]

      if (serviceItem[0] === new Date().toLocaleDateString()) {
        servicesList.push(serviceItem)
        servicesAmount.push(item.val().importe * 1)

        if (item.val().tipo_pago === 'De Contado') {
          amount.push(serviceItem)
          amountList.push(item.val().importe * 1)
        } else if (
          item.val().tipo_pago === 'Con Tarjeta' ||
          item.val().tipo_pago === 'Por Transferencia'
        ) {
          transfer.push(serviceItem)
          transferList.push(item.val().importe * 1)
        }
      }
    })
  })
  servicesDay = servicesAmount.reduce((x, y) => x + y, 0)
  transferDay = transferList.reduce((x, y) => x + y, 0)
  amountDay = amountList.reduce((x, y) => x + y, 0)

  var doc = new jsPDF('p', 'pt', 'letter')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(165, 65, 'CORTE DE CAJA SERVICIOS A DOMICILIO DEL ' + fecha())
  doc.text(165, 75, '________________________________________________')

  doc.text(225, 110, 'Pagos Realizados')
  doc.text(250, 135, 'Total: $ ' + servicesDay)
  doc.autoTable({
    head: [['Fecha', 'Ticket', 'Tipo de Pago', 'Importe']],
    body: servicesList,
    margin: { top: 145 },
    theme: 'grid',
    styles: {
      fontSize: 10,
    },
  })

  doc.addPage('letter', 'p')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(165, 65, 'CORTE DE CAJA SERVICIOS A DOMICILIO DEL ' + fecha())
  doc.text(165, 75, '________________________________________________')
  doc.text(225, 110, 'Ticket Pagados de Contado')
  doc.text(250, 135, 'Total: $ ' + amountDay)
  doc.autoTable({
    head: [['Fecha', 'Ticket', 'Tipo de Pago', 'Importe']],
    body: amount,
    margin: { top: 145 },
    theme: 'grid',
    styles: {
      fontSize: 10,
    },
  })

  doc.addPage('letter', 'p')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(165, 65, 'CORTE DE CAJA SERVICIOS A DOMICILIO DEL ' + fecha())
  doc.text(165, 75, '________________________________________________')
  doc.text(225, 110, 'Ticket Pagados por Transferencia o Tarjeta')
  doc.text(250, 135, 'Total: $ ' + transferDay)
  doc.autoTable({
    head: [['Fecha', 'Ticket', 'Tipo de Pago', 'Importe']],
    body: transfer,
    margin: { top: 145 },
    theme: 'grid',
    styles: {
      fontSize: 10,
    },
  })

  doc.autoPrint()
  doc.output('dataurlnewwindow', { filename: 'Corte.pdf' })
}

export const generateTicket = (print) => {
  var doc = new jsPDF('p', 'pt', [228, 3508])

  doc.addImage(logo, 'WEBP', 50, 30, 125, 50)
  doc.setFontSize(8)
  doc.text(55, 95, 'Carr. Ciudad Hidalgo-Maravatío')
  doc.text(62.5, 105, 'Ciudad Hidalgo, Michoacán')
  doc.text(70, 115, 'Tel. 01(786)-168-01-64')
  doc.text(
    20,
    125,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 135, 'Folio')
  doc.text(print.folio + '', 205, 135, { align: 'right' })
  doc.text(20, 145, 'Fecha')
  doc.text(205, 145, print.fecha, { align: 'right' })
  doc.text(20, 155, 'Hora')
  doc.text(205, 155, print.hora, { align: 'right' })
  doc.text(
    20,
    165,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 175, 'Nombre:')
  doc.text(205, 175, print.cliente, { align: 'right' })
  //doc.text(20, 185, 'Lugar de Entrega:')
  doc.text(20, 185, 'Lugar de Entrega: ' + print.lugar, {
    maxWidth: '185',
    align: 'justify',
  })
  doc.text(20, 205, 'Referencia:   ' + print.referencia, {
    maxWidth: '185',
    align: 'justify',
  })
  doc.text(
    20,
    255,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 265, 'Descripción')
  doc.text(205, 265, 'Servicio a Domicilio', { align: 'right' })
  doc.text(20, 275, 'Importe')
  doc.text(205, 275, '$ ' + print.importe, { align: 'right' })
  doc.text(
    20,
    285,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 295, 'Total a Pagar')
  doc.text(205, 295, '$ ' + print.importe, { align: 'right' })
  doc.text(70, 320, '¡Gracias por su compra!')

  doc.autoPrint()
  doc.output('dataurlnewwindow', { filename: 'Ticket ' + print.folio + '.pdf' })
}

export const weeklyDeliveries = (date1, date2, chofer, horas) => {
  const kenworth = []
  const inter = []
  const ford = []
  const ford2014 = []
  const nissan = []
  const list = []

  const weeklyTime = 176400000
  let factoryTime = 0

  const limit = date2
  limit.setDate(limit.getDate() + 1)

  onValue(ref(database, 'entregas/'), (snapshot) => {
    snapshot.forEach((item) => {
      if (
        item.val().date >= new Date(date1).setHours(0) &&
        item.val().date <= limit
      ) {
        let time = 0
        const trip = [
          item.val().fecha,
          item.val().chofer,
          item.val().unidad,
          item.val().producto,
          item.val().ayudante,
        ]
        if (item.val().chofer === chofer) {
          switch (item.val().unidad) {
            case 'Kenworth':
              kenworth.push(1)
              break
            case 'International':
              inter.push(1)
              break
            case 'Camión Ford':
              ford.push(1)
              break
            case 'Camioneta Ford':
              ford2014.push(1)
              break
            case 'Nissan 2014':
              nissan.push(1)
              break
            default:
              break
          }
          list.push(trip)
          time = item.val().coming - item.val().start
          factoryTime = factoryTime + time
          //console.log(factoryTime + ' ' + item.val().fecha + ' ' +  item.val().unidad + ' ' + item.val().cliente)
          //console.log(new Date(date1).setHours(0))
        }
      }
    })
  })
  let time = weeklyTime - factoryTime

  var doc = new jsPDF('p', 'pt', 'letter')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(
    190,
    65,
    'REPORTE DE VIAJES DEL ' + formatDate(date1) + ' AL ' + formatDate(date2)
  )
  doc.text(165, 75, '________________________________________________')
  doc.text(250, 110, 'Chofer: ' + chofer)
  doc.text(250, 140, 'Camión Kenworth: ' + kenworth.length, { align: 'right' })
  doc.text(500, 140, 'Camión International: ' + inter.length, {
    align: 'right',
  })
  doc.text(250, 160, 'Camión Ford: ' + ford.length, { align: 'right' })
  doc.text(500, 160, 'Camioneta Ford: ' + ford2014.length, { align: 'right' })
  doc.text(500, 180, 'Camioneta Nissan: ' + nissan.length, { align: 'right' })
  doc.text(200, 200, 'Tiempo en Fabrica ' + msToTime(time) + ' horas')
  doc.text(250, 220, 'Horas Extra ' + horas + ' hora(s)')

  doc.autoTable({
    head: [['Fecha', 'Chofer', 'Unidad', 'Producto', 'Ayudante']],
    body: list,
    margin: { top: 240 },
    theme: 'grid',
    styles: {
      fontSize: 10,
    },
  })

  doc.autoPrint()
  doc.output('dataurlnewwindow', { filename: 'Viajes' + chofer + '.pdf' })
}

export const statusReportService = (date1, date2, unidad) => {
  let tickets = 0
  let costos = 0
  let combustible = 0
  let comisionChofer = 0
  let comisionAyudante = 0
  let sueldoChofer = 0
  let sueldoAyudante = 0
  let imss = 0
  let infonavit = 0
  let mantenimiento = 0
  let neumaticos = 0

  const limit = date2
  limit.setDate(limit.getDate() + 1)

  onValue(ref(database, 'fletes/'), (snapshot) => {
    snapshot.forEach((item) => {
      if (item.val().horaSistema >= date1 && item.val().horaSistema <= limit) {
        if (
          item.val().unidad1 === unidad ||
          item.val().unidad2 === unidad ||
          item.val().unidad3 === unidad
        ) {
          tickets = tickets + item.val().importe * 1
          combustible = combustible + item.val().cCombustible
          sueldoChofer = sueldoChofer + item.val().cHhChofer
          sueldoAyudante = sueldoAyudante + item.val().cHhAyudante
          imss = imss + item.val().cImss
          infonavit = infonavit + item.val().cInfonavit
          mantenimiento = mantenimiento + item.val().cMantenimiento
          neumaticos = neumaticos + item.val().cNeumaticos
          comisionChofer = comisionChofer + item.val().cComision
        } else if (unidad === 'Todas las Unidades') {
          tickets = tickets + item.val().importe * 1
          combustible = combustible + item.val().cCombustible
          sueldoChofer = sueldoChofer + item.val().cHhChofer
          sueldoAyudante = sueldoAyudante + item.val().cHhAyudante
          imss = imss + item.val().cImss
          infonavit = infonavit + item.val().cInfonavit
          mantenimiento = mantenimiento + item.val().cMantenimiento
          neumaticos = neumaticos + item.val().cNeumaticos
          comisionChofer = comisionChofer + item.val().cComision
        }
      }
    })
  })

  costos =
    combustible +
    sueldoChofer +
    sueldoAyudante +
    imss +
    infonavit +
    mantenimiento +
    neumaticos +
    comisionChofer +
    comisionAyudante

  let utilidad = tickets - costos

  let doc = new jsPDF('p', 'pt', 'letter')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(
    165,
    65,
    'ESTADO DE RESULTADOS DEL ' + formatDate(date1) + ' AL ' + formatDate(date2)
  )
  doc.text(285, 85, 'EN BASE A ' + unidad)
  doc.text(
    30,
    95,
    '________________________________________________________________'
  )

  doc.text(50, 130, '• Ingresos')
  doc.text(500, 130, `$ ${tickets}`)
  doc.text(50, 160, '• Gastos')
  doc.text(80, 180, '• Combustible')
  doc.text(430, 180, `$ ${combustible}`)
  doc.text(80, 200, '• Neumaticos')
  doc.text(430, 200, `$ ${neumaticos}`)
  doc.text(80, 220, '• Mantenimiento')
  doc.text(430, 220, `$ ${mantenimiento}`)
  doc.text(80, 240, '• Sueldo Chofer')
  doc.text(430, 240, `$ ${sueldoChofer}`)
  doc.text(80, 260, '• Sueldo Ayudante')
  doc.text(430, 260, `$ ${sueldoAyudante}`)
  doc.text(80, 280, '• Comisiones')
  doc.text(430, 280, `$ ${comisionChofer}`)
  doc.text(80, 300, '• IMSS')
  doc.text(430, 300, `$ ${imss}`)
  doc.text(80, 320, '• Infonavit')
  doc.text(430, 320, `$ ${infonavit}`)
  doc.text(50, 350, '• Total Gastos')
  doc.text(500, 350, `$ ${costos}`)
  doc.text(50, 370, '• Utilidad')
  doc.text(500, 370, `$ ${utilidad}`)

  //doc.autoPrint()
  doc.output('dataurlnewwindow', {
    filename: 'Estado de Resultados ' + unidad + '.pdf',
  })
}

export const driverReport = (info) => {
  let doc = new jsPDF('p', 'pt', [228, 3508])

  doc.addImage(logo, 'PNG', 50, 30, 125, 50)
  doc.setFontSize(12)
  doc.text(35, 95, 'Reporte de Pedido de Chofer')

  doc.setFontSize(8)
  doc.text(
    20,
    105,
    '----------------------------------------------------------------------'
  )
  doc.text(25, 115, 'Fecha')
  doc.text(205, 115, info.fecha, { align: 'right' })
  doc.text(25, 125, 'Cliente')
  doc.text(205, 125, info.cliente, { align: 'right' })
  doc.text(25, 135, 'Producto')
  doc.text(205, 135, info.producto, { align: 'right' })
  doc.text(25, 145, 'Lugar')
  doc.text(205, 145, info.lugar, { align: 'right' })
  doc.text(25, 155, 'Chofer')
  doc.text(205, 155, info.chofer, { align: 'right' })
  doc.text(25, 165, 'Hora de Inicio de Carga')
  doc.text(205, 165, info.hrInicio, { align: 'right' })
  doc.text(25, 185, 'Hora de Salida de Planta')
  doc.text(205, 185, '_____________', { align: 'right' })
  doc.text(25, 205, 'Hora de Llegada a Planta')
  doc.text(205, 205, '_____________', { align: 'right' })
  doc.text(25, 225, 'Hora Estimada de Regreso')
  doc.text(205, 225, '_____________', { align: 'right' })
  doc.text(
    20,
    235,
    '----------------------------------------------------------------------'
  )

  doc.output('dataurlnewwindow', { filename: 'Reporte ' + info.date + '.pdf' })
}
