import { useState, useLayoutEffect } from 'react'
import { onValue, ref, getDatabase, push, update } from 'firebase/database'
import { database } from '../configs/firebase'
import { costosValues } from './cost'
import { getPlace } from './places'
import { fecha, horaTicket } from './datetime'
import Swal from 'sweetalert2'

export var addFolio = 1

export let totalMinimo = 0
export let totalInter = 0
export let totalMaximo = 0
export let totalRecomendado = 0

let costos = {
  camino: 0,
  hhChofer: 0,
  hhAyudante: 0,
  infonavit: 0,
  imss: 0,
  comision: 0,
  neumaticos: 0,
  combustible: 0,
  mantenimiento: 0,
  utilidad: 0,
}

export const FetchServices = () => {
  const [services, setServices] = useState([])

  useLayoutEffect(() => {
    onValue(
      ref(database, 'fletes/'),
      (snapshot) => {
        const servicesList = []
        snapshot.forEach((item) => {
          const serviceItem = {
            id: item.key,
            ...item.val(),
          }
          servicesList.push(serviceItem)
          if (snapshot.size === 0) {
            addFolio = 1
          } else {
            addFolio = snapshot.size + 1
          }
        })
        setServices(servicesList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])

  return [services]
}

export const getServices = () => {
  try {
    let servicesList = []
    onValue(ref(database, 'fletes/'), (snap) => {
      snap.forEach((item) => {
        const serviceItem = {
          id: item.key,
          ...item.val(),
        }
        servicesList.push(serviceItem)
      })
    })
    return servicesList
  } catch (error) {
    return {
      isSucess: false,
      errorMessage: error.message,
    }
  }
}

export const calculateService = (
  lugar,
  cliente,
  referencia,
  unidad1,
  salidas1,
  producto1,
  cantidad1,
  unidad2,
  salidas2,
  producto2,
  cantidad2,
  unidad3,
  salidas3,
  producto3,
  cantidad3
) => {
  //Reinicio de Variables
  costos.camino = 0
  costos.hhChofer = 0
  costos.hhAyudante = 0
  costos.infonavit = 0
  costos.imss = 0
  costos.comision = 0
  costos.neumaticos = 0
  costos.combustible = 0
  costos.mantenimiento = 0

  //Variables y Metodos Generales
  const cost = costosValues
  const lugarInfo = getPlace(lugar)
  let total1Min = 0
  let total1Int = 0
  let total1Max = 0
  let total2Min = 0
  let total2Int = 0
  let total2Max = 0
  let total3Min = 0
  let total3Int = 0
  let total3Max = 0
  let tiempo1 = 0
  let tiempo2 = 0
  let tiempo3 = 0
  let distancia = lugarInfo.distancia

  costos.utilidad = cost.utilidad * 1

  //  Tiempos de entrega para unidades 1, 2 y 3
  if (unidad1 === 'Nissan 2014' || unidad1 === 'Camioneta Ford') {
    tiempo1 = tiempo1 + lugarInfo.tiempoDoble * 1
  } else if (unidad1 === 'International' || unidad1 === 'Camión Ford') {
    tiempo1 = tiempo1 + lugarInfo.tiempoCamion * 1
  } else if (unidad1 === 'Kenworth') {
    tiempo1 = tiempo1 + lugarInfo.tiempoTorton * 1
  }

  if (unidad2 === 'Nissan 2014' || unidad2 === 'Camioneta Ford') {
    tiempo2 = tiempo2 + lugarInfo.tiempoDoble * 1
  } else if (unidad2 === 'International' || unidad2 === 'Camión Ford') {
    tiempo2 = tiempo2 + lugarInfo.tiempoCamion * 1
  } else if (unidad2 === 'Kenworth') {
    tiempo2 = tiempo2 + lugarInfo.tiempoTorton * 1
  }

  if (unidad3 === 'Nissan 2014' || unidad3 === 'Camioneta Ford') {
    tiempo3 = tiempo3 + lugarInfo.tiempoDoble * 1
  } else if (unidad3 === 'International' || unidad3 === 'Camión Ford') {
    tiempo3 = tiempo3 + lugarInfo.tiempoCamion * 1
  } else if (unidad3 === 'Kenworth') {
    tiempo3 = tiempo3 + lugarInfo.tiempoTorton * 1
  }

  //Variables de lugar
  let camino1 = 0
  if (lugarInfo.camino === 'Sí') {
    camino1 = cost.camino * salidas1
  }

  //Variables de Chofer 1 y Ayudante 1
  let hhChofer1 = 0
  let hhAyudante1 = 0
  let imss1 = 0
  let infonavit1 = 0
  let comisionChofer1 = 0
  let comisionAyudante1 = 0

  hhChofer1 = cost.hhChofer * tiempo1 * salidas1

  if (unidad1 === 'Nissan 2014' || unidad1 === 'Camioneta Ford') {
    switch (producto1) {
      case 'CPC-MORT':
        if (cantidad1 > 30) {
          hhAyudante1 = cost.hhAyudante * tiempo1 * salidas1
          comisionAyudante1 = cost.com3tonAyudante * salidas1
        }
        break
      case 'Block Hueco':
      case 'Block Macizo':
      case 'Tabicon':
        if (cantidad1 > 200) {
          hhAyudante1 = cost.hhAyudante * tiempo1 * salidas1
          comisionAyudante1 = cost.com3tonAyudante * salidas1
        }
        break
      case 'Adoquin Hex':
      case 'Adoquin Tab':
      case 'Adoquin Bet':
        if (cantidad1 > 250) {
          hhAyudante1 = cost.hhAyudante * tiempo1 * salidas1
          comisionAyudante1 = cost.com3tonAyudante * salidas1
        }
        break
      case 'Poste 2x10x10':
      case 'Poste 2.5x10x10':
        if (cantidad1 > 30) {
          hhAyudante1 = cost.hhAyudante * tiempo1 * salidas1
          comisionAyudante1 = cost.com3tonAyudante * salidas1
        }
        break
      case 'TC6':
      case 'TC8':
        if (cantidad1 > 45) {
          hhAyudante1 = cost.hhAyudante * tiempo1 * salidas1
          comisionAyudante1 = cost.com3tonAyudante * salidas1
        }
        break
      case 'TC10':
      case 'TC12':
        if (cantidad1 > 35) {
          hhAyudante1 = cost.hhAyudante * tiempo1 * salidas1
          comisionAyudante1 = cost.com3tonAyudante * salidas1
        }
        break
      case 'TC15':
      case 'TC18':
        if (cantidad1 > 10) {
          hhAyudante1 = cost.hhAyudante * tiempo1 * salidas1
          comisionAyudante1 = cost.com3tonAyudante * salidas1
        }
        break
      case 'Poste 2x15x15':
      case 'Poste 2.5x15x15':
      case 'TC24':
      case 'Brocal':
        hhAyudante1 = cost.hhAyudante * tiempo1 * salidas1
        comisionAyudante1 = cost.com3tonAyudante * salidas1
        break
    }
  } else if (
    unidad1 === 'Kenworth' ||
    unidad1 === 'International' ||
    unidad1 === 'Camión Ford'
  ) {
    hhAyudante1 = cost.hhAyudante * tiempo1 * salidas1
  }

  if (hhAyudante1 === 0) {
    infonavit1 = cost.infonavit * tiempo1 * salidas1
    imss1 = cost.imss * tiempo1 * salidas1
  } else {
    infonavit1 = cost.infonavit * 2 * tiempo1 * salidas1
    imss1 = cost.imss * 2 * tiempo1 * salidas1
  }

  //Variables a calcular en base a la Unidad 1
  let neumaticos1 = 0
  let combustible1 = 0
  let mantenimeinto1 = 0

  switch (unidad1) {
    case 'Kenworth':
      neumaticos1 = distancia * cost.neuKen * salidas1
      combustible1 = cost.diesel * (distancia / cost.dKenworth) * salidas1
      if (distancia < 15) {
        mantenimeinto1 = cost.manKenworthD1 * salidas1
      } else if (distancia >= 15 && distancia < 30) {
        mantenimeinto1 = cost.manKenworthD2 * salidas1
      } else if (distancia >= 30) {
        mantenimeinto1 = cost.manKenworthD3 * salidas1
      }
      comisionChofer1 = cost.comKenworth * salidas1
      comisionAyudante1 = cost.comKenworthAyudante * salidas1
      break
    case 'Camión Ford':
    case 'International':
      neumaticos1 = distancia * cost.neu10ton * salidas1
      if (unidad1 === 'Camión Ford') {
        combustible1 = cost.diesel * (distancia / cost.dFord) * salidas1
        mantenimeinto1 = cost.manFord * distancia * salidas1
      } else if (unidad1 === 'International') {
        combustible1 = cost.diesel * (distancia / cost.dInter) * salidas1
        mantenimeinto1 = cost.manInter * distancia * salidas1
      }
      comisionChofer1 = cost.com10ton * salidas1
      comisionAyudante1 = cost.com10tonAyudante * salidas1
      break
    case 'Camioneta Ford':
      neumaticos1 = distancia * cost.neu3ton * salidas1
      combustible1 = cost.gasolina * (distancia / cost.gFord) * salidas1
      mantenimeinto1 = cost.manFord2014 * distancia * salidas1
      comisionChofer1 = cost.com3ton * salidas1
      break
    case 'Nissan 2014':
      neumaticos1 = distancia * cost.neu1ton * salidas1
      combustible1 = cost.gasolina * (distancia / cost.gNissan) * salidas1
      mantenimeinto1 = cost.manNissan * distancia * salidas1
      comisionChofer1 = cost.com1ton * salidas1
      break
  }

  //Variables Sub total Unidad 1
  let subtotal1 =
    (hhChofer1 +
      hhAyudante1 +
      imss1 +
      infonavit1 +
      comisionChofer1 +
      comisionAyudante1 +
      neumaticos1 +
      combustible1 +
      mantenimeinto1 +
      camino1) *
    1

  costos.camino = costos.camino + camino1
  costos.hhChofer = costos.hhChofer + hhChofer1
  costos.hhAyudante = costos.hhAyudante + hhAyudante1
  costos.infonavit = costos.infonavit + infonavit1
  costos.imss = costos.imss + imss1
  costos.comision = costos.comision + comisionChofer1 + comisionAyudante1
  costos.neumaticos = costos.neumaticos + neumaticos1
  costos.combustible = costos.combustible + combustible1
  costos.mantenimiento = costos.mantenimiento + mantenimeinto1

  let utilidad1 = 0

  if (distancia >= 30) {
    utilidad1 = subtotal1 * (cost.utilidad / 100)
  }

  if (unidad1 === 'Kenworth') {
    total1Min = (subtotal1 + utilidad1) * 1
    total1Int = total1Min + 150
    total1Max = total1Min + 300
  } else if (
    unidad1 === 'Camión Ford' ||
    unidad1 === 'International' ||
    unidad1 === 'Camioneta Ford'
  ) {
    total1Min = (subtotal1 + utilidad1) * 1
    total1Int = total1Min + 100
    total1Max = total1Min + 200
  } else if (unidad1 === 'Nissan 2014') {
    total1Min = (subtotal1 + utilidad1) * 1
    total1Int = total1Min + 75
    total1Max = total1Min + 150
  }

  if (
    unidad2 !== '' &&
    salidas2 !== '' &&
    producto2 !== '' &&
    cantidad2 !== ''
  ) {
    //Variables de lugar
    let camino2 = 0
    if (lugarInfo.camino === 'Sí') {
      camino2 = cost.camino * salidas2
    }

    //Variables de Chofer 2 y Ayudante 2
    let hhChofer2 = 0
    let hhAyudante2 = 0
    let imss2 = 0
    let infonavit2 = 0
    let comisionChofer2 = 0
    let comisionAyudante2 = 0

    hhChofer2 = cost.hhChofer * tiempo2 * salidas2

    if (unidad2 === 'Nissan 2014' || unidad2 === 'Camioneta Ford') {
      switch (producto2) {
        case 'CPC-MORT':
          if (cantidad2 > 30) {
            hhAyudante2 = cost.hhAyudante * tiempo2 * salidas2
            comisionAyudante2 = cost.com3tonAyudante * salidas2
          }
          break
        case 'Block Hueco':
        case 'Block Macizo':
        case 'Tabicon':
          if (cantidad2 > 200) {
            hhAyudante2 = cost.hhAyudante * tiempo2 * salidas2
            comisionAyudante2 = cost.com3tonAyudante * salidas2
          }
          break
        case 'Adoquin Hex':
        case 'Adoquin Tab':
        case 'Adoquin Bet':
          if (cantidad2 > 250) {
            hhAyudante2 = cost.hhAyudante * tiempo2 * salidas2
            comisionAyudante2 = cost.com3tonAyudante * salidas2
          }
          break
        case 'Poste 2x10x10':
        case 'Poste 2.5x10x10':
          if (cantidad2 > 30) {
            hhAyudante2 = cost.hhAyudante * tiempo2 * salidas2
            comisionAyudante2 = cost.com3tonAyudante * salidas2
          }
          break
        case 'TC6':
        case 'TC8':
          if (cantidad2 > 45) {
            hhAyudante2 = cost.hhAyudante * tiempo2 * salidas2
            comisionAyudante2 = cost.com3tonAyudante * salidas2
          }
          break
        case 'TC10':
        case 'TC12':
          if (cantidad2 > 35) {
            hhAyudante2 = cost.hhAyudante * tiempo2 * salidas2
            comisionAyudante2 = cost.com3tonAyudante * salidas2
          }
          break
        case 'TC15':
        case 'TC18':
          if (cantidad2 > 10) {
            hhAyudante2 = cost.hhAyudante * tiempo2 * salidas2
            comisionAyudante2 = cost.com3tonAyudante * salidas2
          }
          break
        case 'Poste 2x15x15':
        case 'Poste 2.5x15x15':
        case 'TC24':
        case 'Brocal':
          hhAyudante2 = cost.hhAyudante * tiempo2 * salidas2
          comisionAyudante2 = cost.com3tonAyudante * salidas2
          break
      }
    } else if (
      unidad2 === 'Kenworth' ||
      unidad2 === 'International' ||
      unidad2 === 'Camión Ford'
    ) {
      hhAyudante2 = cost.hhAyudante * tiempo2 * salidas2
    }

    if (hhAyudante2 === 0) {
      infonavit2 = cost.infonavit * tiempo2 * salidas2
      imss2 = cost.imss * tiempo2 * salidas2
    } else {
      infonavit2 = cost.infonavit * 2 * tiempo2 * salidas2
      imss2 = cost.imss * 2 * tiempo2 * salidas2
    }

    //Variables a calcular en base a la Unidad 2
    let neumaticos2 = 0
    let combustible2 = 0
    let mantenimeinto2 = 0

    switch (unidad2) {
      case 'Kenworth':
        neumaticos2 = distancia * cost.neuKen * salidas2
        combustible2 = cost.diesel * (distancia / cost.dKenworth) * salidas2
        if (distancia < 15) {
          mantenimeinto2 = cost.manKenworthD1 * salidas2
        } else if (distancia >= 15 && distancia < 30) {
          mantenimeinto2 = cost.manKenworthD2 * salidas2
        } else if (distancia >= 30) {
          mantenimeinto2 = cost.manKenworthD3 * salidas2
        }
        comisionChofer2 = cost.comKenworth * salidas2
        comisionAyudante2 = cost.comKenworthAyudante * salidas2
        break
      case 'Camión Ford':
      case 'International':
        neumaticos2 = distancia * cost.neu10ton * salidas2
        if (unidad2 === 'Camión Ford') {
          combustible2 = cost.diesel * (distancia / cost.dFord) * salidas2
          mantenimeinto2 = cost.manFord * distancia * salidas2
        } else if (unidad2 === 'International') {
          combustible2 = cost.diesel * (distancia / cost.dInter) * salidas2
          mantenimeinto2 = cost.manInter * distancia * salidas2
        }
        comisionChofer2 = cost.com10ton * salidas2
        comisionAyudante2 = cost.com10tonAyudante * salidas2
        break
      case 'Camioneta Ford':
        neumaticos2 = distancia * cost.neu3ton * salidas2
        combustible2 = cost.gasolina * (distancia / cost.gFord) * salidas2
        mantenimeinto2 = cost.manFord2014 * distancia * salidas2
        comisionChofer2 = cost.com3ton * salidas2
        break
      case 'Nissan 2014':
        neumaticos2 = distancia * cost.neu1ton * salidas2
        combustible2 = cost.gasolina * (distancia / cost.gNissan) * salidas2
        mantenimeinto2 = cost.manNissan * distancia * salidas2
        comisionChofer2 = cost.com1ton * salidas2
        break
    }

    //Variables Sub total Unidad 1
    let subtotal2 =
      (hhChofer2 +
        hhAyudante2 +
        imss2 +
        infonavit2 +
        comisionChofer2 +
        comisionAyudante2 +
        neumaticos2 +
        combustible2 +
        mantenimeinto2 +
        camino2) *
      1

    costos.camino = costos.camino + camino2
    costos.hhChofer = costos.hhChofer + hhChofer2
    costos.hhAyudante = costos.hhAyudante + hhAyudante2
    costos.infonavit = costos.infonavit + infonavit2
    costos.imss = costos.imss + imss2
    costos.comision = costos.comision + comisionChofer2 + comisionAyudante2
    costos.neumaticos = costos.neumaticos + neumaticos2
    costos.combustible = costos.combustible + combustible2
    costos.mantenimiento = costos.mantenimiento + mantenimeinto2

    let utilidad2 = 0

    if (distancia >= 30) {
      utilidad2 = subtotal2 * (cost.utilidad / 100)
    }

    if (unidad2 === 'Kenworth') {
      total2Min = (subtotal2 + utilidad2) * 1
      total2Int = total2Min + 150
      total2Max = total2Min + 300
    } else if (
      unidad2 === 'Camión Ford' ||
      unidad2 === 'International' ||
      unidad2 === 'Camioneta Ford'
    ) {
      total2Min = (subtotal2 + utilidad2) * 1
      total2Int = total2Min + 100
      total2Max = total2Min + 200
    } else if (unidad2 === 'Nissan 2014') {
      total2Min = (subtotal2 + utilidad2) * 1
      total2Int = total2Min + 75
      total2Max = total2Min + 150
    }
  }

  if (
    unidad3 !== '' &&
    salidas3 !== '' &&
    producto3 !== '' &&
    cantidad3 !== ''
  ) {
    //Variables de lugar
    let camino3 = 0
    if (lugarInfo.camino === 'Sí') {
      camino3 = cost.camino * salidas3
    }

    //Variables de Chofer 3 y Ayudante 3
    let hhChofer3 = 0
    let hhAyudante3 = 0
    let imss3 = 0
    let infonavit3 = 0
    let comisionChofer3 = 0
    let comisionAyudante3 = 0

    hhChofer3 = cost.hhChofer * tiempo3 * salidas3

    if (unidad3 === 'Nissan 2014' || unidad3 === 'Camioneta Ford') {
      switch (producto3) {
        case 'CPC-MORT':
          if (cantidad3 > 30) {
            hhAyudante3 = cost.hhAyudante * tiempo3 * salidas3
            comisionAyudante3 = cost.com3tonAyudante * salidas3
          }
          break
        case 'Block Hueco':
        case 'Block Macizo':
        case 'Tabicon':
          if (cantidad3 > 200) {
            hhAyudante3 = cost.hhAyudante * tiempo3 * salidas3
            comisionAyudante3 = cost.com3tonAyudante * salidas3
          }
          break
        case 'Adoquin Hex':
        case 'Adoquin Tab':
        case 'Adoquin Bet':
          if (cantidad3 > 250) {
            hhAyudante3 = cost.hhAyudante * tiempo3 * salidas3
            comisionAyudante3 = cost.com3tonAyudante * salidas3
          }
          break
        case 'Poste 2x10x10':
        case 'Poste 2.5x10x10':
          if (cantidad3 > 30) {
            hhAyudante3 = cost.hhAyudante * tiempo3 * salidas3
            comisionAyudante3 = cost.com3tonAyudante * salidas3
          }
          break
        case 'TC6':
        case 'TC8':
          if (cantidad3 > 45) {
            hhAyudante3 = cost.hhAyudante * tiempo3 * salidas3
            comisionAyudante3 = cost.com3tonAyudante * salidas3
          }
          break
        case 'TC10':
        case 'TC12':
          if (cantidad3 > 35) {
            hhAyudante3 = cost.hhAyudante * tiempo3 * salidas3
            comisionAyudante3 = cost.com3tonAyudante * salidas3
          }
          break
        case 'TC15':
        case 'TC18':
          if (cantidad3 > 10) {
            hhAyudante3 = cost.hhAyudante * tiempo3 * salidas3
            comisionAyudante3 = cost.com3tonAyudante * salidas3
          }
          break
        case 'Poste 2x15x15':
        case 'Poste 2.5x15x15':
        case 'TC24':
        case 'Brocal':
          hhAyudante3 = cost.hhAyudante * tiempo3 * salidas3
          comisionAyudante3 = cost.com3tonAyudante * salidas3
          break
      }
    } else if (
      unidad3 === 'Kenworth' ||
      unidad3 === 'International' ||
      unidad3 === 'Camión Ford'
    ) {
      hhAyudante3 = cost.hhAyudante * tiempo3 * salidas3
    }

    if (hhAyudante3 === 0) {
      infonavit3 = cost.infonavit * tiempo3 * salidas3
      imss3 = cost.imss * tiempo3 * salidas3
    } else {
      infonavit3 = cost.infonavit * 2 * tiempo3 * salidas3
      imss3 = cost.imss * 2 * tiempo3 * salidas3
    }

    //Variables a calcular en base a la Unidad 3
    let neumaticos3 = 0
    let combustible3 = 0
    let mantenimeinto3 = 0

    switch (unidad3) {
      case 'Kenworth':
        neumaticos3 = distancia * cost.neuKen * salidas3
        combustible3 = cost.diesel * (distancia / cost.dKenworth) * salidas3
        if (distancia < 15) {
          mantenimeinto3 = cost.manKenworthD1 * salidas3
        } else if (distancia >= 15 && distancia < 30) {
          mantenimeinto3 = cost.manKenworthD2 * salidas3
        } else if (distancia >= 30) {
          mantenimeinto3 = cost.manKenworthD3 * salidas3
        }
        comisionChofer3 = cost.comKenworth * salidas3
        comisionAyudante3 = cost.comKenworthAyudante * salidas3
        break
      case 'Camión Ford':
      case 'International':
        neumaticos3 = distancia * cost.neu10ton * salidas3
        if (unidad3 === 'Camión Ford') {
          combustible3 = cost.diesel * (distancia / cost.dFord) * salidas3
          mantenimeinto3 = cost.manFord * distancia * salidas3
        } else if (unidad3 === 'International') {
          combustible3 = cost.diesel * (distancia / cost.dInter) * salidas3
          mantenimeinto3 = cost.manInter * distancia * salidas3
        }
        comisionChofer3 = cost.com10ton * salidas3
        comisionAyudante3 = cost.com10tonAyudante * salidas3
        break
      case 'Camioneta Ford':
        neumaticos3 = distancia * cost.neu3ton * salidas3
        combustible3 = cost.gasolina * (distancia / cost.gFord) * salidas3
        mantenimeinto3 = cost.manFord2014 * distancia * salidas3
        comisionChofer3 = cost.com3ton * salidas3
        break
      case 'Nissan 2014':
        neumaticos3 = distancia * cost.neu1ton * salidas3
        combustible3 = cost.gasolina * (distancia / cost.gNissan) * salidas3
        mantenimeinto3 = cost.manNissan * distancia * salidas3
        comisionChofer3 = cost.com1ton * salidas3
        break
    }

    //Variables Sub total Unidad 3
    let subtotal3 =
      (hhChofer3 +
        hhAyudante3 +
        imss3 +
        infonavit3 +
        comisionChofer3 +
        comisionAyudante3 +
        neumaticos3 +
        combustible3 +
        mantenimeinto3 +
        camino3) *
      1

    costos.camino = costos.camino + camino3
    costos.hhChofer = costos.hhChofer + hhChofer3
    costos.hhAyudante = costos.hhAyudante + hhAyudante3
    costos.infonavit = costos.infonavit + infonavit3
    costos.imss = costos.imss + imss3
    costos.comision = costos.comision + comisionChofer3 + comisionAyudante3
    costos.neumaticos = costos.neumaticos + neumaticos3
    costos.combustible = costos.combustible + combustible3
    costos.mantenimiento = costos.mantenimiento + mantenimeinto3

    let utilidad3 = 0

    if (distancia >= 30) {
      utilidad3 = subtotal3 * (cost.utilidad / 100)
    }

    if (unidad3 === 'Kenworth') {
      total3Min = (subtotal3 + utilidad3) * 1
      total3Int = total3Min + 150
      total3Max = total3Min + 300
    } else if (
      unidad3 === 'Camión Ford' ||
      unidad3 === 'International' ||
      unidad3 === 'Camioneta Ford'
    ) {
      total3Min = (subtotal3 + utilidad3) * 1
      total3Int = total3Min + 100
      total3Max = total3Min + 200
    } else if (unidad3 === 'Nissan 2014') {
      total3Min = (subtotal3 + utilidad3) * 1
      total3Int = total3Min + 75
      total3Max = total3Min + 150
    }
  }

  if (distancia < 6) {
    switch (unidad1) {
      case 'Nissan 2014':
        total1Min = 200 * salidas1
        total1Int = 200 * salidas1
        total1Max = 200 * salidas1
        break
      case 'Camioneta Ford':
        total1Min = 400 * salidas1
        total1Int = 500 * salidas1
        total1Max = 600 * salidas1
        break
      case 'Camión Ford':
      case 'International':
        total1Min = 600 * salidas1
        total1Int = 650 * salidas1
        total1Max = 700 * salidas1
        break
      case 'Kenworth':
        total1Min = 1000 * salidas1
        total1Int = 1100 * salidas1
        total1Max = 1200 * salidas1
        break
      default:
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal',
          footer:
            '<a>Error de calculo de unidad 1 en distancia de 0 a 5 Km</a>',
        })
    }
    if (unidad2 !== '') {
      switch (unidad2) {
        case 'Nissan 2014':
          total2Min = 200 * salidas2
          total2Int = 200 * salidas2
          total2Max = 200 * salidas2
          break
        case 'Camioneta Ford':
          total2Min = 400 * salidas2
          total2Int = 500 * salidas2
          total2Max = 600 * salidas2
          break
        case 'Camión Ford':
        case 'International':
          total2Min = 600 * salidas2
          total2Int = 650 * salidas2
          total2Max = 700 * salidas2
          break
        case 'Kenworth':
          total2Min = 1000 * salidas2
          total2Int = 1100 * salidas2
          total2Max = 1200 * salidas2
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 2 en distancia 1 de 0 a 5 Km</a>',
          })
      }
    }
    if (unidad3 !== '') {
      switch (unidad3) {
        case 'Nissan 2014':
          total3Min = 200 * salidas3
          total3Int = 200 * salidas3
          total3Max = 200 * salidas3
          break
        case 'Camioneta Ford':
          total3Min = 400 * salidas3
          total3Int = 500 * salidas3
          total3Max = 600 * salidas3
          break
        case 'Camión Ford':
        case 'International':
          total3Min = 600 * salidas3
          total3Int = 650 * salidas3
          total3Max = 700 * salidas3
          break
        case 'Kenworth':
          total3Min = 1000 * salidas3
          total3Int = 1100 * salidas3
          total3Max = 1200 * salidas3
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 3 en distancia 1 de 0 a 5 Km</a>',
          })
      }
    }
  } else if (distancia >= 6 && distancia < 10) {
    switch (unidad1) {
      case 'Nissan 2014':
        total1Min = 250 * salidas1
        total1Int = 250 * salidas1
        total1Max = 250 * salidas1
        break
      case 'Camioneta Ford':
        total1Min = 450 * salidas1
        total1Int = 550 * salidas1
        total1Max = 650 * salidas1
        break
      case 'Camión Ford':
      case 'International':
        total1Min = 700 * salidas1
        total1Int = 750 * salidas1
        total1Max = 800 * salidas1
        break
      case 'Kenworth':
        total1Min = 1100 * salidas1
        total1Int = 1300 * salidas1
        total1Max = 1500 * salidas1
        break
      default:
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal',
          footer:
            '<a>Error de calculo de unidad 1 en distancia de 6 a 10 Km</a>',
        })
    }
    if (unidad2 !== '') {
      switch (unidad2) {
        case 'Nissan 2014':
          total2Min = 250 * salidas2
          total2Int = 250 * salidas2
          total2Max = 250 * salidas2
          break
        case 'Camioneta Ford':
          total2Min = 450 * salidas2
          total2Int = 550 * salidas2
          total2Max = 650 * salidas2
          break
        case 'Camión Ford':
        case 'International':
          total2Min = 700 * salidas2
          total2Int = 750 * salidas2
          total2Max = 800 * salidas2
          break
        case 'Kenworth':
          total2Min = 1100 * salidas2
          total2Int = 1300 * salidas2
          total2Max = 1500 * salidas2
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 2 en distancia de 6 a 10 Km</a>',
          })
      }
    }
    if (unidad3 !== '') {
      switch (unidad3) {
        case 'Nissan 2014':
          total3Min = 250 * salidas3
          total3Int = 250 * salidas3
          total3Max = 250 * salidas3
          break
        case 'Camioneta Ford':
          total3Min = 450 * salidas3
          total3Int = 550 * salidas3
          total3Max = 650 * salidas3
          break
        case 'Camión Ford':
        case 'International':
          total3Min = 700 * salidas3
          total3Int = 750 * salidas3
          total3Max = 800 * salidas3
          break
        case 'Kenworth':
          total3Min = 1100 * salidas3
          total3Int = 1300 * salidas3
          total3Max = 1500 * salidas3
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 3 en distancia de 6 a 10 Km</a>',
          })
      }
    }
  } else if (distancia >= 10 && distancia < 16) {
    switch (unidad1) {
      case 'Nissan 2014':
        total1Min = 280 * salidas1
        total1Int = 280 * salidas1
        total1Max = 280 * salidas1
        break
      case 'Camioneta Ford':
        total1Min = 480 * salidas1
        total1Int = 580 * salidas1
        total1Max = 680 * salidas1
        break
      case 'Camión Ford':
      case 'International':
        total1Min = 800 * salidas1
        total1Int = 850 * salidas1
        total1Max = 900 * salidas1
        break
      case 'Kenworth':
        total1Min = 1200 * salidas1
        total1Int = 1350 * salidas1
        total1Max = 1500 * salidas1
        break
      default:
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal',
          footer:
            '<a>Error de calculo de unidad 1 en distancia de 11 a 15 Km</a>',
        })
    }
    if (unidad2 !== '') {
      switch (unidad2) {
        case 'Nissan 2014':
          total2Min = 280 * salidas2
          total2Int = 280 * salidas2
          total2Max = 280 * salidas2
          break
        case 'Camioneta Ford':
          total2Min = 480 * salidas2
          total2Int = 580 * salidas2
          total2Max = 680 * salidas2
          break
        case 'Camión Ford':
        case 'International':
          total2Min = 800 * salidas2
          total2Int = 850 * salidas2
          total2Max = 900 * salidas2
          break
        case 'Kenworth':
          total2Min = 1200 * salidas2
          total2Int = 1350 * salidas2
          total2Max = 1500 * salidas2
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 2 en distancia de 11 a 15 Km</a>',
          })
      }
    }
    if (unidad3 !== '') {
      switch (unidad3) {
        case 'Nissan 2014':
          total3Min = 280 * salidas3
          total3Int = 280 * salidas3
          total3Max = 280 * salidas3
          break
        case 'Camioneta Ford':
          total3Min = 480 * salidas3
          total3Int = 580 * salidas3
          total3Max = 680 * salidas3
          break
        case 'Camión Ford':
        case 'International':
          total3Min = 800 * salidas3
          total3Int = 850 * salidas3
          total3Max = 900 * salidas3
          break
        case 'Kenworth':
          total3Min = 1200 * salidas3
          total3Int = 1350 * salidas3
          total3Max = 1500 * salidas3
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 3 en distancia de 11 a 15 Km</a>',
          })
      }
    }
  } else if (distancia >= 16 && distancia < 20) {
    switch (unidad1) {
      case 'Nissan 2014':
        total1Min = 330 * salidas1
        total1Int = 330 * salidas1
        total1Max = 330 * salidas1
        break
      case 'Camioneta Ford':
        total1Min = 530 * salidas1
        total1Int = 630 * salidas1
        total1Max = 730 * salidas1
        break
      case 'Camión Ford':
      case 'International':
        total1Min = 900 * salidas1
        total1Int = 1000 * salidas1
        total1Max = 1100 * salidas1
        break
      case 'Kenworth':
        total1Min = 1300 * salidas1
        total1Int = 1450 * salidas1
        total1Max = 1300 * salidas1
        break
      default:
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal',
          footer:
            '<a>Error de calculo de unidad 1 en distancia de 16 a 20 Km</a>',
        })
    }
    if (unidad2 !== '') {
      switch (unidad2) {
        case 'Nissan 2014':
          total2Min = 330 * salidas2
          total2Int = 330 * salidas2
          total2Max = 330 * salidas2
          break
        case 'Camioneta Ford':
          total2Min = 530 * salidas2
          total2Int = 630 * salidas2
          total2Max = 730 * salidas2
          break
        case 'Camión Ford':
        case 'International':
          total2Min = 900 * salidas2
          total2Int = 1000 * salidas2
          total2Max = 1100 * salidas2
          break
        case 'Kenworth':
          total2Min = 1300 * salidas2
          total2Int = 1450 * salidas2
          total2Max = 1300 * salidas2
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 2 en distancia de 16 a 20 Km</a>',
          })
      }
    }
    if (unidad3 !== '') {
      switch (unidad3) {
        case 'Nissan 2014':
          total3Min = 330 * salidas3
          total3Int = 330 * salidas3
          total3Max = 330 * salidas3
          break
        case 'Camioneta Ford':
          total3Min = 530 * salidas3
          total3Int = 630 * salidas3
          total3Max = 730 * salidas3
          break
        case 'Camión Ford':
        case 'International':
          total3Min = 900 * salidas3
          total3Int = 1000 * salidas3
          total3Max = 1100 * salidas3
          break
        case 'Kenworth':
          total3Min = 1300 * salidas3
          total3Int = 1450 * salidas3
          total3Max = 1600 * salidas3
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 3 en distancia de 16 a 20 Km</a>',
          })
      }
    }
  } else if (distancia >= 20 && distancia < 26) {
    switch (unidad1) {
      case 'Nissan 2014':
        total1Min = 350 * salidas1
        total1Int = 350 * salidas1
        total1Max = 350 * salidas1
        break
      case 'Camioneta Ford':
        total1Min = 550 * salidas1
        total1Int = 650 * salidas1
        total1Max = 750 * salidas1
        break
      case 'Camión Ford':
      case 'International':
        total1Min = 1000 * salidas1
        total1Int = 1100 * salidas1
        total1Max = 1200 * salidas1
        break
      case 'Kenworth':
        total1Min = 1400 * salidas1
        total1Int = 1550 * salidas1
        total1Max = 1700 * salidas1
        break
      default:
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal',
          footer:
            '<a>Error de calculo de unidad 1 en distancia de 21 a 55 Km</a>',
        })
    }
    if (unidad2 !== '') {
      switch (unidad2) {
        case 'Nissan 2014':
          total2Min = 350 * salidas2
          total2Int = 350 * salidas2
          total2Max = 350 * salidas2
          break
        case 'Camioneta Ford':
          total2Min = 550 * salidas2
          total2Int = 650 * salidas2
          total2Max = 750 * salidas2
          break
        case 'Camión Ford':
        case 'International':
          total2Min = 1000 * salidas2
          total2Int = 1100 * salidas2
          total2Max = 1200 * salidas2
          break
        case 'Kenworth':
          total2Min = 1400 * salidas2
          total2Int = 1550 * salidas2
          total2Max = 1700 * salidas2
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 2 en distancia de 21 a 25 Km</a>',
          })
      }
    }
    if (unidad3 !== '') {
      switch (unidad3) {
        case 'Nissan 2014':
          total3Min = 350 * salidas3
          total3Int = 350 * salidas3
          total3Max = 350 * salidas3
          break
        case 'Camioneta Ford':
          total3Min = 550 * salidas3
          total3Int = 650 * salidas3
          total3Max = 750 * salidas3
          break
        case 'Camión Ford':
        case 'International':
          total3Min = 1000 * salidas3
          total3Int = 1100 * salidas3
          total3Max = 1200 * salidas3
          break
        case 'Kenworth':
          total3Min = 1400 * salidas3
          total3Int = 1550 * salidas3
          total3Max = 1700 * salidas3
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 3 en distancia de 21 a 25 Km</a>',
          })
      }
    }
  } else if (distancia >= 26 && distancia < 30) {
    switch (unidad1) {
      case 'Nissan 2014':
        total1Min = 370 * salidas1
        total1Int = 370 * salidas1
        total1Max = 370 * salidas1
        break
      case 'Camioneta Ford':
        total1Min = 570 * salidas1
        total1Int = 670 * salidas1
        total1Max = 770 * salidas1
        break
      case 'Camión Ford':
      case 'International':
        total1Min = 1100 * salidas1
        total1Int = 1200 * salidas1
        total1Max = 1300 * salidas1
        break
      case 'Kenworth':
        total1Min = 1500 * salidas1
        total1Int = 1650 * salidas1
        total1Max = 1800 * salidas1
        break
      default:
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal',
          footer:
            '<a>Error de calculo de unidad 1 en distancia de 26 a 59 Km</a>',
        })
    }
    if (unidad2 !== '') {
      switch (unidad2) {
        case 'Nissan 2014':
          total2Min = 370 * salidas2
          total2Int = 370 * salidas2
          total2Max = 370 * salidas2
          break
        case 'Camioneta Ford':
          total2Min = 570 * salidas2
          total2Int = 670 * salidas2
          total2Max = 770 * salidas2
          break
        case 'Camión Ford':
        case 'International':
          total2Min = 1100 * salidas2
          total2Int = 1200 * salidas2
          total2Max = 1300 * salidas2
          break
        case 'Kenworth':
          total2Min = 1500 * salidas2
          total2Int = 1650 * salidas2
          total2Max = 1800 * salidas2
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 2 en distancia de 26 a 29 Km</a>',
          })
      }
    }
    if (unidad3 !== '') {
      switch (unidad3) {
        case 'Nissan 2014':
          total3Min = 370 * salidas3
          total3Int = 370 * salidas3
          total3Max = 370 * salidas3
          break
        case 'Camioneta Ford':
          total3Min = 570 * salidas3
          total3Int = 670 * salidas3
          total3Max = 770 * salidas3
          break
        case 'Camión Ford':
        case 'International':
          total3Min = 1100 * salidas3
          total3Int = 1200 * salidas3
          total3Max = 1300 * salidas3
          break
        case 'Kenworth':
          total3Min = 1500 * salidas3
          total3Int = 1650 * salidas3
          total3Max = 1800 * salidas3
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo salió mal',
            footer:
              '<a>Error de calculo de unidad 3 en distancia de 26 a 29 Km</a>',
          })
      }
    }
    //console.log(total1Min, total1Int, total1Max)
    //console.log(total2Min, total2Int, total2Max)
    //console.log(total3Min, total3Int, total3Max)
  }

  totalMinimo = Math.round((total1Min + total2Min + total3Min) * 1)
  totalInter = Math.round((total1Int + total2Int + total3Int) * 1)
  totalMaximo = Math.round((total1Max + total2Max + total3Max) * 1)

  if (unidad1 === 'Nissan 2014' || unidad1 === 'Camioneta Ford') {
    totalRecomendado = totalInter
  } else if (unidad1 === 'Camión Ford' || unidad1 === 'International') {
    if (distancia < 16) {
      totalRecomendado = totalMaximo
    } else if (distancia >= 16 && distancia < 30) {
      totalRecomendado = totalInter
    } else if (distancia >= 30) {
      totalRecomendado = totalMinimo
    }
  } else if (unidad1 === 'Kenworth') {
    if (distancia < 30) {
      totalRecomendado = totalInter
    } else {
      totalRecomendado = totalMinimo
    }
  }

  return true
}

export const addService = (
  lugar,
  cliente,
  referencia,
  salidas1,
  salidas2,
  salidas3,
  cantidad1,
  cantidad2,
  cantidad3,
  unidad1,
  unidad2,
  unidad3,
  producto1,
  producto2,
  producto3,
  importe,
  distancia
) => {
  const db = getDatabase()

  push(ref(db, 'fletes'), {
    cliente: cliente,
    estado: 'A crédito',
    fecha: fecha(),
    folio: addFolio,
    hora: horaTicket(),
    horaSistema: new Date().getTime(),
    importe: importe,
    lugar: lugar,
    referencia: referencia,
    pago: '...',
    unidad1: unidad1,
    unidad2: unidad2,
    unidad3: unidad3,
    cantidad1: cantidad1,
    cantidad2: cantidad2,
    cantidad3: cantidad3,
    producto1: producto1,
    producto2: producto2,
    producto3: producto3,
    salidas1: salidas1,
    salidas2: salidas2,
    salidas3: salidas3,
    cCamino: Math.round(costos.camino),
    cHhChofer: Math.round(costos.hhChofer),
    cHhAyudante: Math.round(costos.hhAyudante),
    cInfonavit: Math.round(costos.infonavit),
    cImss: Math.round(costos.imss),
    cComision: Math.round(costos.comision),
    cNeumaticos: Math.round(costos.neumaticos),
    cCombustible: Math.round(costos.combustible),
    cMantenimiento: Math.round(costos.mantenimiento),
    cTotal: totalMinimo,
    cargoExtra: importe * 1 - totalMinimo,
    cUtilidad: costos.utilidad,
    distancia: distancia,
  })

  totalMaximo = 0
  totalInter = 0
  totalMinimo = 0
}

export const updateService = (update1, estado, pago) => {
  const db = getDatabase()

  update(ref(db, `fletes/${update1.id}`), {
    estado: estado,
    pago: pago,
  })
}
