import { ref, getDatabase, push, set, remove, onValue } from 'firebase/database'
import { database } from '../configs/firebase'
import { formatTime, msToTime } from './datetime'

export const getDeliveries = () => {
  const deliveriesList = []
  onValue(ref(database, 'entregas/'), (snapshot) => {
    snapshot.forEach((item) => {
      const deliveriesItem = {
        id: item.key,
        ...item.val(),
      }
      deliveriesList.push(deliveriesItem)
    })
  })

  return deliveriesList
}

export const addDeliveries = (
  chofer,
  client,
  estado,
  fecha,
  startDate,
  entrada,
  inicio,
  salida,
  coming,
  place,
  out,
  producto,
  start,
  tiempo,
  unidad,
  time,
  ayudante,
  complemento
) => {
  const db = getDatabase()

  let carga = ''
  let entrega = ''
  let total = ''

  if (coming === '') {
    coming = '...'
  }

  if (start === '') {
    start = '...'
  }

  if (out === '') {
    out = '...'
  }

  if (inicio === '') {
    inicio = '...'
  } else {
    start = inicio.getTime()
    inicio = formatTime(inicio)
  }

  if (salida === '') {
    salida = '...'
  } else {
    out = salida.getTime()
    salida = formatTime(salida)
  }

  if (entrada === '') {
    entrada = '...'
  } else {
    coming = entrada.getTime()
    entrada = formatTime(entrada)
  }

  if (estado === '') {
    estado = '...'
  }

  if (out === '...' && out === '') {
    tiempo = '-'
  } else {
    tiempo = getEstimatedTime(out, time)

    if (start === '...' && start === '') {
      carga = msToTime(out - start)
    } else {
      carga = '...'
    }
  }

  if (coming === '...' && coming === '') {
    entrega = msToTime(coming - out)
  } else {
    entrega = '...'
  }

  if (carga !== '' && carga !== '...' && entrega !== '' && entrega !== '...') {
    let x = out * 1 - start * 1
    let y = coming * 1 - out * 1
    let z = x + y
    total = msToTime(z)
  }

  push(ref(db, 'entregas'), {
    chofer: chofer,
    cliente: client,
    estado: estado,
    fecha: fecha,
    date: startDate.getTime(),
    hrEntrada: entrada,
    hrInicio: inicio,
    hrSalida: salida,
    coming: coming,
    lugar: place,
    out: out,
    producto: producto,
    start: start,
    tiempo: tiempo,
    unidad: unidad,
    time: time,
    carga: carga,
    entrega: entrega,
    total: total,
    ayudante: ayudante,
    complemento: complemento,
  })
}

export const updateDeliveries = (
  id,
  chofer,
  client,
  estado,
  fecha,
  startDate,
  hrEntrada,
  hrInicio,
  hrSalida,
  entrada,
  inicio,
  salida,
  coming,
  place,
  out,
  producto,
  start,
  tiempo,
  unidad,
  time,
  ayudante,
  complemento
) => {
  const db = getDatabase()
  let carga = ''
  let entrega = ''
  let total = ''

  if (coming === '') {
    coming = '...'
  }

  if (start === '') {
    start = '...'
  }

  if (out === '') {
    out = '...'
  }

  if (hrInicio === '') {
    hrInicio = '...'
  } else {
    if (inicio === '' || inicio === '...') {
      inicio = '...'
    } else {
      start = inicio.getTime()
      hrInicio = formatTime(inicio)
    }
  }

  if (hrSalida === '') {
    hrSalida = '...'
  } else {
    if (salida === '' || salida === '...') {
      salida = '...'
    } else {
      out = salida.getTime()
      hrSalida = formatTime(salida)
    }
  }

  if (hrEntrada === '') {
    hrEntrada = '...'
  } else {
    if (entrada === '' || entrada === '...') {
      entrada = '...'
    } else {
      coming = entrada.getTime()
      hrEntrada = formatTime(entrada)
    }
  }

  if (out === '...' && out === '') {
    tiempo = '-'
  } else {
    tiempo = getEstimatedTime(out, time)
    if (start !== '...' || start !== '') {
      let x = out * 1 - start * 1
      carga = msToTime(x)
    } else {
      carga = '...'
    }
  }

  if (coming !== '...' || coming !== '') {
    let x = coming * 1 - out * 1
    entrega = msToTime(x)
  } else {
    entrega = '...'
  }

  if (carga !== '' && carga !== '...' && entrega !== '' && entrega !== '...') {
    let x = out * 1 - start * 1
    let y = coming * 1 - out * 1
    let z = x + y
    total = msToTime(z)
  }

  fecha =
    startDate.getDate() +
    '/' +
    (startDate.getMonth() + 1) +
    '/' +
    startDate.getFullYear()

  set(ref(db, `entregas/${id}`), {
    chofer: chofer,
    cliente: client,
    estado: estado,
    fecha: fecha,
    date: startDate.getTime(),
    hrEntrada: hrEntrada,
    hrInicio: hrInicio,
    hrSalida: hrSalida,
    coming: coming,
    lugar: place,
    out: out,
    producto: producto,
    start: start,
    tiempo: tiempo,
    unidad: unidad,
    time: time,
    carga: carga,
    entrega: entrega,
    total: total,
    ayudante: ayudante,
    complemento: complemento,
  })
}

export const deleteDeliveries = (id) => {
  const db = getDatabase()

  remove(ref(db, `entregas/${id}`))
}

export const getEstimatedTime = (out, time) => {
  var miliseconds = time * 60 * 60 * 1000
  var estimateMiliseconds
  var estimateReturn
  var estimateHour

  if (out === '...') {
    estimateReturn = '-'
  } else {
    estimateMiliseconds = out + miliseconds
    estimateHour = new Date(estimateMiliseconds)
    estimateReturn = formatTime(estimateHour)
  }

  return estimateReturn
}
