import { useState, useLayoutEffect } from 'react'
import { onValue, ref, getDatabase, push, set, remove } from 'firebase/database'
import { database } from '../configs/firebase'

export const useDrivers = () => {
  const [drivers, setDrivers] = useState([])

  useLayoutEffect(() => {
    onValue(
      ref(database, 'choferes/'),
      (snapshot) => {
        const driversList = []
        snapshot.forEach((item) => {
          const driverItem = {
            id: item.key,
            nombre: item.val().nombre,
            puesto: item.val().puesto,
          }
          driversList.push(driverItem)
        })
        setDrivers(driversList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])
  return [drivers]
}

export const getDrivers = () => {
  try {
    const drivers = []
    onValue(ref(database, 'choferes/'), (snapshot) => {
      snapshot.forEach((item) => {
        const driverItem = {
          id: item.key,
          ...item.val(),
        }
        drivers.push(driverItem)
      })
    })
    return drivers
  } catch (error) {}
}

export const addDriver = (values) => {
  const db = getDatabase()

  push(ref(db, 'choferes'), {
    nombre: values.nombre,
    puesto: values.puesto,
  })
}

export const updateDriver = (id, nombre, puesto) => {
  //console.log(id)
  const db = getDatabase()

  set(ref(db, `choferes/${id}`), {
    nombre: nombre,
    puesto: puesto,
  })
}

export const deleteDriver = (id) => {
  //console.log(id)
  const db = getDatabase()

  remove(ref(db, `choferes/${id}`))
}
