import React, { useLayoutEffect } from 'react'

import { zonas } from './array'

import { addPlace, updatePlace } from '../../services/places'
import { useForm, Form } from '../UI/Form'
import Controls from '../UI/Controls'

import Swal from 'sweetalert2'

const initialValues = {
  nombre: '',
  distancia: '',
  zona: '',
  camino: '',
  tiempoDoble: '',
  tiempoCamion: '',
  tiempoTorton: '',
  observaciones: '',
}

const LugaresForm = ({ setOpenPopup, update, flag, setFlag }) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  var {
    nombre,
    distancia,
    zona,
    camino,
    tiempoDoble,
    tiempoCamion,
    tiempoTorton,
    observaciones,
  } = values

  const validate = () => {
    let temp = {}

    temp.nombre = (nombre || '').length === 0 ? 'Este campo es requerido' : ''
    temp.distancia =
      (distancia || '').length === 0 ? 'Este campo es requerido' : ''
    temp.zona = (zona || '').length === 0 ? 'Este campo es requerido' : ''
    temp.camino = (camino || '').length === 0 ? 'Este campo es requerido' : ''
    temp.tiempoDoble =
      (tiempoDoble || '').length === 0 ? 'Este campo es requerido' : ''
    temp.tiempoCamion =
      (tiempoCamion || '').length === 0 ? 'Este campo es requerido' : ''
    temp.tiempoTorton =
      (tiempoTorton || '').length === 0 ? 'Este campo es requerido' : ''

    setErrors({
      ...temp,
    })

    return Object.values(temp).every((x) => x === '')
  }

  useLayoutEffect(() => {
    if (update !== '') {
      setValues({ ...update })
    }
  }, [setValues, update, flag])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      if (flag === false) {
        addPlace(
          nombre,
          distancia,
          zona,
          camino,
          tiempoDoble,
          tiempoCamion,
          tiempoTorton,
          observaciones
        )
      } else {
        updatePlace(
          update.id,
          nombre,
          distancia,
          zona,
          camino,
          tiempoDoble,
          tiempoCamion,
          tiempoTorton,
          observaciones
        )
      }
      resetForm()
      setValues({})
      setFlag(false)
      setOpenPopup(false)
      Swal.fire('Hecho', 'Lugar registrado con éxito', 'success')
    }
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
      }}
    >
      <Form onSubmit={handleSubmit}>
        <div className="large-group">
          <div className="mini-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="nombre">* Nombre</label>
            <Controls.Input
              className="input-select"
              id="nombre"
              type="text"
              name="nombre"
              value={nombre}
              onChange={handleInputChange}
              error={errors.nombre}
            />
          </div>
          <div className="mini-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="distancia">* Distancia</label>
            <Controls.Input
              className="input-select"
              id="distancia"
              type="text"
              name="distancia"
              value={distancia}
              onChange={handleInputChange}
              error={errors.distancia}
            />
          </div>
          <div className="mini-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="zona">* Zona</label>
            <Controls.Select
              id="zona"
              type="text"
              name="zona"
              value={zona}
              onChange={handleInputChange}
              options={zonas}
            />
            {errors.zona !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.zona}
              </label>
            )}
          </div>
          <div className="mini-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="camino">* Camino Accidentado</label>
            <Controls.Select
              className="input-select"
              id="camino"
              type="text"
              name="camino"
              value={camino}
              onChange={handleInputChange}
              options={[
                { id: 'Sí', title: 'Sí' },
                { id: 'No', title: 'No' },
              ]}
            />
            {errors.camino !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.camino}
              </label>
            )}
          </div>
          <div className="small-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="tiempoDoble">* Tiempo Camioneta Doble</label>
            <Controls.Input
              className="input-select"
              id="tiempoDoble"
              type="text"
              name="tiempoDoble"
              value={tiempoDoble}
              onChange={handleInputChange}
              error={errors.tiempoDoble}
            />
          </div>
          <div className="small-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="tiempoCamion">* Tiempo Camión</label>
            <Controls.Input
              className="input-select"
              id="tiempoCamion"
              type="text"
              name="tiempoCamion"
              value={tiempoCamion}
              onChange={handleInputChange}
              error={errors.tiempoCamion}
            />
          </div>
          <div className="small-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="tiempoTorton">* Tiempo Torton</label>
            <Controls.Input
              className="input-select"
              id="tiempoTorton"
              type="text"
              name="tiempoTorton"
              value={tiempoTorton}
              onChange={handleInputChange}
              error={errors.tiempoTorton}
            />
          </div>
          <div className="textarea-div" style={{ marginBottom: '20px' }}>
            <label htmlFor="observaciones">Observaciones</label>
            <Controls.TextArea
              className="input-select"
              id="observaciones"
              type="text"
              name="observaciones"
              value={observaciones}
              onChange={handleInputChange}
            />
          </div>
          <input id="submit" className="btn" type="submit" name="submit" />
        </div>
      </Form>
    </div>
  )
}

export default LugaresForm
