import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  IconButton,
  TextField,
  Button,
} from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { Add, Search, Edit, Delete } from '@mui/icons-material'

import useTable from '../UI/Table'

import { useState } from 'react'
import { deleteDriver, useDrivers } from '../../services/drivers'
import { CustomDialog } from '../UI/Dialog'
import { WorkerForm } from './workerForm'
import Swal from 'sweetalert2'

const headCells = [
  { id: 'nombre', label: 'Nombre' },
  { id: 'puesto', label: 'Puesto' },
  { id: 'acciones', label: 'Acciones' },
]

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
    outline: '0',
    marginBottom: '20px',
    borderRadius: '20px',
    border: '0',
    backgroundColor: '#e9e9e9',
    fontSize: '14px',
    appearance: 'none',
    resize: 'none',
    padding: '2px',
  },
  input: {
    border: 'none',
    height: '2rem',
  },
  iconButton: {
    width: '2rem',
    height: '2rem',
    marginRight: '-10px',
  },
  selectButton: {
    backgroundColor: '#00544e',
    color: 'white',
    height: '30px',
    width: '30px',
    transition: '0.3s all ease',
    '&:hover': {
      backgroundColor: '#009696',
      border: '1px solid #00544e',
    },
  },
  searchInput: {
    width: '100%',
    marginRight: '5%',
  },
}))

export const WorkerSelect = ({
  person,
  setOpenPopup,
  setDriver,
  setHelper,
}) => {
  const [drivers] = useDrivers()

  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [update, setUpdate] = useState({})
  const [flag, setFlag] = useState(false)
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(drivers, headCells, filterFn)

  const handleSearch = ({ target }) => {
    const { value } = target
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items
        else
          return items.filter((x) =>
            x.nombre.toLowerCase().includes(value.toLowerCase())
          )
      },
    })
  }

  return (
    <>
      <div style={{ marginTop: '15px', marginBottom: '30px' }}>
        <Toolbar>
          <div className="medium-group" style={{ marginRight: '1vw' }}>
            <TextField
              variant="standard"
              className={classes.searchInput}
              sx={{
                height: '40px',
                borderRadius: '25px',
                backgroundColor: '#e9e9e9',
                fontSize: '14px',
                padding: '5px',
              }}
              placeholder="Buscar Trabajador"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginLeft: '10px' }}>
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </div>
          <div className="medium-group">
            <Button
              variant="contained"
              startIcon={<Add />}
              sx={{
                backgroundColor: '#009696',
                padding: '12px 15px',
                height: '40px',
                fontSize: '1em;',
                border: '1px solid #00544e',
                borderRadius: '30px',
                transition: '0.3s all ease',
                color: 'secondary',
                '&:hover': {
                  backgroundColor: '#00544e',
                  border: '1px solid #009696',
                },
                '@media (max-width: 700px)': {
                  //marginTop: '20px',
                  marginBottom: '20px',
                },
              }}
              onClick={() => {
                setOpen(true)
              }}
            >
              Agregar Trabajador
            </Button>
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordAfterPagingAndSorting().map((worker) => (
              <TableRow
                key={worker.id}
                onClick={() => {
                  if (person === 'C') {
                    setDriver(worker.nombre)
                  } else if (person === 'A') {
                    setHelper(worker.nombre)
                  }
                  setOpenPopup(false)
                }}
              >
                <TableCell data-label="Nombre: ">{worker.nombre}</TableCell>
                <TableCell data-label="Puesto: ">{worker.puesto}</TableCell>
                <TableCell data-label="Acciones: ">
                  <IconButton
                    onClick={() => {
                      setUpdate(worker)
                      setFlag(true)
                      setOpen(true)
                    }}
                    sx={{
                      backgroundColor: 'orange',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      marginRight: '5px',
                      '&:hover': {
                        backgroundColor: 'darkorange',
                        border: '1px solid orange',
                      },
                    }}
                  >
                    <Edit sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      Swal.fire({
                        title: '¿Estas seguro de eliminar este registro?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'Sí',
                        denyButtonText: 'No',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteDriver(worker.id)
                          Swal.fire(
                            'Hecho',
                            'Registro eliminado exitosamente',
                            'success'
                          )
                        } else if (result.isDenied) {
                          Swal.fire('Operación abortada', '', 'info')
                        }
                      })
                    }}
                    sx={{
                      backgroundColor: 'red',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      marginRight: '5px',
                      '&:hover': {
                        backgroundColor: 'darkred',
                        border: '1px solid red',
                      },
                    }}
                  >
                    <Delete sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <CustomDialog
          title="Agregar Trabajador"
          openPopup={open}
          setOpenPopup={setOpen}
        >
          <WorkerForm
            setOpenPopup={setOpen}
            update={update}
            flag={flag}
            setFlag={setFlag}
          />
        </CustomDialog>
      </div>
    </>
  )
}
