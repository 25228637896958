import React, { useLayoutEffect, useState } from 'react'

import { useForm, Form } from '../UI/Form'
import { unidades } from './array'
import { ClientsSelect } from './client'
import { PlaceSelect } from './place'
import Controls from '../UI/Controls'

import {
  LocalizationProvider,
  TimePicker,
  DesktopDatePicker,
} from '@mui/x-date-pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Checkbox, makeStyles } from '@material-ui/core'
import { Tooltip, IconButton } from '@mui/material'
import { Close, OpenInBrowser } from '@mui/icons-material'
import { Box } from '@mui/material'
import Swal from 'sweetalert2'
import { CustomDialog } from '../UI/Dialog'
import { addDeliveries, updateDeliveries } from '../../services/deliveries'
import { useDrivers } from '../../services/drivers'
import { WorkerSelect } from './worker'

const initialValues = {
  chofer: '',
  cliente: '',
  estado: '',
  fecha: '',
  date: '',
  hrEntrada: '',
  hrInicio: '',
  hrSalida: '',
  coming: '',
  lugar: '',
  out: '',
  producto: '',
  start: '',
  tiempo: '',
  unidad: '',
  ayudante: '',
}

const useStyles = makeStyles(() => ({
  iconButton: {
    width: '2rem',
    height: '2rem',
    marginRight: '-10px',
  },
  checkBox: {
    '&.Mui-checked': {
      color: '#00544e',
    },
  },
}))

export const DeliveriesForm = ({
  setOpenPopup,
  update,
  flag,
  setFlag,
  id,
  setCurrentId,
}) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  const [startDate, setStartDate] = useState(new Date())

  const [person, setPerson] = useState('')
  const [helper, setHelper] = useState('')
  const [driver, setDriver] = useState('')
  const [client, setClient] = useState('')
  const [place, setPlace] = useState('')
  const [time, setTime] = useState('')

  var [inicio, setInicio] = useState('')
  var [salida, setSalida] = useState('')
  var [entrada, setEntrada] = useState('')

  const [complemento, setComplemento] = useState(false)

  const [openClient, setOpenClient] = useState(false)
  const [openPlace, setOpenPlace] = useState(false)
  const [openWorkers, setOpenWorkers] = useState(false)

  const classes = useStyles()

  var {
    estado,
    fecha,
    hrEntrada,
    hrInicio,
    hrSalida,
    coming,
    out,
    producto,
    start,
    tiempo,
    unidad,
  } = values

  const validate = () => {
    let temp = {}

    temp.client =
      client.length === 0
        ? 'Este campo es requerido'
        : !/^[a-zA-ZÀ-ÿñÑ\s]*$/.test(client)
        ? 'No se aceptan caracteres especiales'
        : client.length < 4
        ? 'El nombre debe tener al menos 4 caracteres'
        : ''
    temp.driver =
      driver.length === 0
        ? 'Este campo es requerido'
        : !/^[a-zA-ZÀ-ÿñÑ\s]*$/.test(driver)
        ? 'No se aceptan caracteres especiales'
        : ''
    temp.helper = helper.length === 0 ? 'Este campo es requerido' : ''
    temp.place =
      place.length === 0
        ? 'Este campo es requerido'
        : place.length < 4
        ? 'El nombre debe tener al menos 4 caracteres'
        : ''
    temp.producto = producto.length === 0 ? 'Este campo es requerido' : ''
    temp.unidad = unidad.length === 0 ? 'Este campo es requerido' : ''

    setErrors({
      ...temp,
    })

    return Object.values(temp).every((x) => x === '')
  }

  useLayoutEffect(() => {
    if (id !== '') {
      setValues({ ...update })
      setClient(update.cliente)
      setDriver(update.chofer)
      setHelper(update.ayudante)
      setPlace(update.lugar)
      setTime(update.time)
      setComplemento(update.complemento)

      setStartDate(new Date(update.date))

      if (update.start === '...') {
        setInicio('')
      } else {
        setInicio(new Date(update.start))
      }

      if (update.out === '...') {
        setSalida('')
      } else {
        setSalida(new Date(update.out))
      }

      if (update.coming === '...') {
        setEntrada('')
      } else {
        setEntrada(new Date(update.coming))
      }
    }
  }, [setValues, update, flag, id])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      if (flag === false) {
        fecha =
          startDate.getDate() +
          '/' +
          (startDate.getMonth() + 1) +
          '/' +
          startDate.getFullYear()
        addDeliveries(
          driver,
          client,
          estado,
          fecha,
          startDate,
          entrada,
          inicio,
          salida,
          coming,
          place,
          out,
          producto,
          start,
          tiempo,
          unidad,
          time,
          helper,
          complemento
        )
      } else {
        updateDeliveries(
          id,
          driver,
          client,
          estado,
          fecha,
          startDate,
          hrEntrada,
          hrInicio,
          hrSalida,
          entrada,
          inicio,
          salida,
          coming,
          place,
          out,
          producto,
          start,
          tiempo,
          unidad,
          time,
          helper,
          complemento
        )
      }
      resetForm()
      setValues({})
      setFlag(false)
      setOpenPopup(false)
      setCurrentId('')
      Swal.fire('Hecho', 'Entrega registrada con éxito', 'success')
    }
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
      }}
    >
      <Form onSubmit={handleSubmit}>
        <div className="large-group">
          <div className="micro-group">
            <label htmlFor="fecha">Fecha</label>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <div className="medium-group" style={{ width: '82.5%' }}>
                      <input
                        className="input-select"
                        ref={inputRef}
                        {...inputProps}
                      />
                    </div>
                    <Tooltip title="Seleccionar Fecha">
                      <div style={{ marginTop: '-20px' }}>
                        {InputProps?.endAdornment}
                      </div>
                    </Tooltip>
                  </Box>
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="medium-group">
            <label htmlFor="cliente">Cliente</label>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <div
                style={{
                  width: '100%',
                  marginBottom: '20px',
                }}
              >
                <Controls.Input
                  id="cliente"
                  type="text"
                  name="cliente"
                  disabled
                  value={client}
                  onChange={handleInputChange}
                  error={errors.client}
                />
              </div>
              <div
                style={{
                  width: '20%',
                  marginLeft: '5px',
                }}
              >
                <Tooltip title="Seleccionar Cliente">
                  <IconButton
                    onClick={() => {
                      setOpenClient(true)
                    }}
                    sx={{ width: '2rem', height: '2rem' }}
                  >
                    <OpenInBrowser />
                  </IconButton>
                </Tooltip>
              </div>
              <div
                style={{
                  width: '20%',
                }}
              >
                <Tooltip title="Reiniciar Busqueda">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      setClient('')
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="micro-group" style={{ paddingTop: '20px' }}>
            <label htmlFor="complemento">Complemento</label>
            <Checkbox
              id="complemento"
              name="complemento"
              checked={complemento}
              onChange={(e) => setComplemento(e.target.checked)}
              className={classes.checkBox}
            />
          </div>
          <div className="small-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="chofer">* Chofer</label>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <div
                style={{
                  width: '100%',
                  marginBottom: '20px',
                }}
              >
                <Controls.Input
                  id="driver"
                  type="text"
                  name="driver"
                  disabled
                  value={driver}
                  onChange={handleInputChange}
                  error={errors.driver}
                />
              </div>
              <div
                style={{
                  width: '20%',
                  marginLeft: '5px',
                }}
              >
                <Tooltip title="Seleccionar Chofer">
                  <IconButton
                    onClick={() => {
                      setOpenWorkers(true)
                      setPerson('C')
                    }}
                    sx={{ width: '2rem', height: '2rem' }}
                  >
                    <OpenInBrowser />
                  </IconButton>
                </Tooltip>
              </div>
              <div
                style={{
                  width: '20%',
                }}
              >
                <Tooltip title="Reiniciar Busqueda">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      setDriver('')
                      setPerson('')
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="small-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="ayudante">* Ayudante</label>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <div
                style={{
                  width: '100%',
                  marginBottom: '20px',
                }}
              >
                <Controls.Input
                  id="helper"
                  type="text"
                  name="helper"
                  disabled
                  value={helper}
                  onChange={handleInputChange}
                  error={errors.helper}
                />
              </div>
              <div
                style={{
                  width: '20%',
                  marginLeft: '5px',
                }}
              >
                <Tooltip title="Seleccionar Ayudante">
                  <IconButton
                    onClick={() => {
                      setOpenWorkers(true)
                      setPerson('A')
                    }}
                    sx={{ width: '2rem', height: '2rem' }}
                  >
                    <OpenInBrowser />
                  </IconButton>
                </Tooltip>
              </div>
              <div
                style={{
                  width: '20%',
                }}
              >
                <Tooltip title="Reiniciar Busqueda">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      setHelper('')
                      setPerson('')
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="small-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="unidad">Unidad</label>
            <Controls.Select
              id="unidad"
              type="text"
              name="unidad"
              value={unidad}
              onChange={handleInputChange}
              options={unidades}
            />
            {errors.unidad !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.unidad}
              </label>
            )}
          </div>
          <div className="medium-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="producto">Producto</label>
            <Controls.Input
              id="producto"
              type="text"
              name="producto"
              value={producto}
              onChange={handleInputChange}
              error={errors.producto}
            />
          </div>
          <div className="medium-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="lugar">Lugar</label>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <div style={{ width: '100%' }}>
                <Controls.Input
                  id="lugar"
                  type="text"
                  name="lugar"
                  disabled
                  value={place}
                  onChange={handleInputChange}
                  error={errors.place}
                />
              </div>
              <div
                style={{
                  width: '20%',
                  marginLeft: '5px',
                }}
              >
                <Tooltip title="Seleccionar Lugar">
                  <IconButton
                    onClick={() => {
                      setOpenPlace(true)
                    }}
                    sx={{ width: '2rem', height: '2rem' }}
                  >
                    <OpenInBrowser />
                  </IconButton>
                </Tooltip>
              </div>
              <div
                style={{
                  width: '20%',
                }}
              >
                <Tooltip title="Reiniciar Busqueda">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      setPlace('')
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="mini-group">
            <label htmlFor="hrInicio">Hora Inicio de Carga</label>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <TimePicker
                value={inicio}
                onChange={(dateString) => setInicio(dateString)}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <div className="medium-group" style={{ width: '82.5%' }}>
                      <input
                        className="input-select"
                        ref={inputRef}
                        {...inputProps}
                      />
                    </div>
                    <Tooltip title="Seleccionar Hora">
                      <div style={{ marginTop: '-20px' }}>
                        {InputProps?.endAdornment}
                      </div>
                    </Tooltip>
                  </Box>
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="mini-group">
            <label htmlFor="hrSalida">Hora de Salida</label>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <TimePicker
                value={salida}
                onChange={(dateString) => setSalida(dateString)}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <div className="medium-group" style={{ width: '82.5%' }}>
                      <input
                        className="input-select"
                        ref={inputRef}
                        {...inputProps}
                      />
                    </div>
                    <Tooltip title="Seleccionar Hora">
                      <div style={{ marginTop: '-20px' }}>
                        {InputProps?.endAdornment}
                      </div>
                    </Tooltip>
                  </Box>
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="mini-group">
            <label htmlFor="hrEntrada">Hora de Entrada</label>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <TimePicker
                value={entrada}
                onChange={(dateString) => setEntrada(dateString)}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <div className="medium-group" style={{ width: '82.5%' }}>
                      <input
                        className="input-select"
                        ref={inputRef}
                        {...inputProps}
                      />
                    </div>
                    <Tooltip title="Seleccionar Hora">
                      <div style={{ marginTop: '-20px' }}>
                        {InputProps?.endAdornment}
                      </div>
                    </Tooltip>
                  </Box>
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="mini-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="Estado">Estado</label>
            <Controls.Select
              id="estado"
              type="text"
              name="estado"
              value={estado}
              onChange={handleInputChange}
              options={[
                { id: 'En Tránsito', title: 'En Tránsito' },
                { id: 'Próximo a enviar', title: 'Próximo a enviar' },
                { id: 'Envío Completado', title: 'Envío Completado' },
              ]}
            />
          </div>
          <input id="submit" className="btn" type="submit" name="submit" />
          <CustomDialog
            title="Clientes"
            openPopup={openClient}
            setOpenPopup={setOpenClient}
          >
            <ClientsSelect setOpenPopup={setOpenClient} setClient={setClient} />
          </CustomDialog>
          <CustomDialog
            title="Lugares"
            openPopup={openPlace}
            setOpenPopup={setOpenPlace}
          >
            <PlaceSelect
              setOpenPopup={setOpenPlace}
              setPlace={setPlace}
              setTime={setTime}
              unidad={unidad}
            />
          </CustomDialog>
          <CustomDialog
            title="Trabajadores"
            openPopup={openWorkers}
            setOpenPopup={setOpenWorkers}
          >
            <WorkerSelect
              person={person}
              setOpenPopup={setOpenWorkers}
              setDriver={setDriver}
              setHelper={setHelper}
            />
          </CustomDialog>
        </div>
      </Form>
    </div>
  )
}
