import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  IconButton,
  TextField,
} from '@mui/material'
import { makeStyles } from '@material-ui/styles'
import { Search, Edit, Visibility } from '@mui/icons-material'

import useTable from '../UI/Table'

import { useState } from 'react'
import { useCost } from '../../services/cost'
import { CustomDialog } from '../UI/Dialog'
import { CostDetails } from './details'
import CostosForm from './form'

const headCells = [
  { id: 'concepto', label: 'Concepto' },
  { id: 'tipo', label: 'Tipo de Costo' },
  { id: 'importe', label: 'Importe Actual' },
  { id: 'valor', label: 'Valor' },
  { id: 'acciones', label: 'Acciones' },
]

const useStyles = makeStyles({
  searchInput: {
    width: '100%',
    marginRight: '5%',
  },
  buttonAdd: {
    left: '5px',
    backgroundColor: '#dec65e',
  },
  tableCell: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0',
  },
  '@media (max-width: 750px)': {
    buttonAdd: {
      left: '5px',
      backgroundColor: '#dec65e',
    },
  },
})

export const Cost = () => {
  const [cost] = useCost()

  const classes = useStyles()

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(cost, headCells, filterFn)

  const [open, setOpen] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [update, setUpdate] = useState({})
  const [details, setDetails] = useState({})

  const handleSearch = ({ target }) => {
    const { value } = target
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items
        else
          return items.filter((x) =>
            x.concepto.toLowerCase().includes(value.toLowerCase())
          )
      },
    })
  }

  return (
    <>
      <div style={{ marginTop: '3%' }}>
        <h2 className="h1">Costos</h2>
      </div>
      <div className="form" style={{ marginBottom: '30px' }}>
        <Toolbar className="large-group">
          <div className="medium-group" />
          <div
            className="medium-group"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: '5%',
            }}
          >
            <TextField
              variant="standard"
              className={classes.searchInput}
              sx={{
                height: '40px',
                borderRadius: '25px',
                backgroundColor: '#e9e9e9',
                fontSize: '14px',
                padding: '5px',
              }}
              placeholder="Buscar Costo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginLeft: '10px' }}>
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordAfterPagingAndSorting().map((cost) => (
              <TableRow key={cost.id}>
                <TableCell
                  data-label="Concepto: "
                  style={{fontSize: '14px', fontFamily: 'Nunito'}}
                >
                  {cost.concepto}
                </TableCell>
                <TableCell data-label="Tipo: " style={{fontSize: '14px', fontFamily: 'Nunito'}}>
                  {cost.tipo}
                </TableCell>
                <TableCell
                  data-label="Importe Actual: "
                  style={{fontSize: '14px', fontFamily: 'Nunito'}}
                >
                  ${cost.costoAct}
                </TableCell>
                <TableCell data-label="Valor: " style={{fontSize: '14px', fontFamily: 'Nunito'}}>
                  {cost.valor}
                </TableCell>
                <TableCell
                  data-label="Acciones: "
                >
                  <IconButton
                    onClick={() => {
                      setUpdate(cost)
                      setOpen(true)
                    }}
                    sx={{
                      backgroundColor: 'orange',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      marginRight: '5px',
                      '&:hover': {
                        backgroundColor: 'darkorange',
                        border: '1px solid orange',
                      },
                    }}
                  >
                    <Edit sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                  <IconButton
                  onClick={() => {
                    setDetails(cost)
                    setOpenDetails(true)
                  }}
                    sx={{
                      backgroundColor: '#00544e',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      '&:hover': {
                        backgroundColor: '#009696',
                        border: '1px solid #00544e',
                      },
                    }}
                  >
                    <Visibility sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <CustomDialog
          title='Formulario Costos'
          openPopup={open}
          setOpenPopup={setOpen}
        >
          <CostosForm 
            setOpenPopup={setOpen}
            update={update}
          />
        </CustomDialog>
        <CustomDialog
          title='Información Costos'
          openPopup={openDetails}
          setOpenPopup={setOpenDetails}
        >
          <CostDetails 
            setOpenPopup={setOpenDetails}
            details={details}
          />
        </CustomDialog>
      </div>
    </>
  )
}
