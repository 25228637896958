import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  IconButton,
  TextField,
} from '@mui/material'
import { makeStyles } from '@material-ui/styles'
import { Visibility, Search } from '@mui/icons-material'

import useTable from '../UI/Table'

import { useState } from 'react'
import { useMessages } from '../../services/messages'
import { CustomDialog } from '../UI/Dialog'
import { MessageDetails } from './details'

const headCells = [
  { id: 'fecha', label: 'Fecha' },
  { id: 'nombre', label: 'Nombre' },
  { id: 'email', label: 'Correo Electrónico' },
  { id: 'telefono', label: 'Telefono' },
  { id: 'acciones', label: 'Acciones' },
]

const useStyles = makeStyles({
  searchInput: {
    width: '100%',
    marginRight: '5%',
  },
  tableCell: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0',
  },
})

export const Messages = () => {
  const [messages] = useMessages()

  const classes = useStyles()

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(messages, headCells, filterFn)

  const [openDetails, setOpenDetails] = useState(false)
  const [details, setDetails] = useState({})

  const handleSearch = ({ target }) => {
    const { value } = target
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items
        else
          return items.filter((x) =>
            x.nombre.toLowerCase().includes(value.toLowerCase())
          )
      },
    })
  }

  return (
    <>
      <div>
        <div style={{ marginTop: '3%' }}>
          <h2 className="h1">Bandeja de Entrada</h2>
        </div>
        <div className="form" style={{ marginBottom: '30px' }}>
          <Toolbar className="large-group">
            <div className="medium-group" />
            <div
              className="medium-group"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginRight: '5%',
              }}
            >
              <TextField
                variant="standard"
                className={classes.searchInput}
                sx={{
                  height: '40px',
                  borderRadius: '25px',
                  backgroundColor: '#e9e9e9',
                  fontSize: '14px',
                  padding: '5px',
                }}
                placeholder="Buscar Lugar"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ marginLeft: '10px' }}
                    >
                      <Search />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                onChange={handleSearch}
              />
            </div>
          </Toolbar>
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordAfterPagingAndSorting().map((message) => (
                <TableRow key={message.id}>
                  <TableCell data-label="Fecha" style={{fontSize: '14px', fontFamily: 'Nunito'}}>
                    {message.fecha}
                  </TableCell>
                  <TableCell data-label="Nombre" style={{fontSize: '14px', fontFamily: 'Nunito'}}>
                    {message.nombre}
                  </TableCell>
                  <TableCell
                    data-label="Correo Electrónico"
                    style={{fontSize: '14px', fontFamily: 'Nunito'}}
                  >
                    {message.correo}
                  </TableCell>
                  <TableCell
                    data-label="Telefono"
                    style={{fontSize: '14px', fontFamily: 'Nunito'}}
                  >
                    {message.telefono}
                  </TableCell>
                  <TableCell
                    data-label="Acciones"
                    
                  >
                    <IconButton
                      onClick={() => {
                        setDetails(message)
                        setOpenDetails(true)
                      }}
                      sx={{
                        backgroundColor: '#00544e',
                        color: 'white',
                        height: '30px',
                        width: '30px',
                        transition: '0.3s all ease',
                        '&:hover': {
                          backgroundColor: '#009696',
                          border: '1px solid #00544e',
                        },
                      }}
                    >
                      <Visibility sx={{ width: '20px', height: '20px' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
          <CustomDialog
            title="Información Mensajes"
            openPopup={openDetails}
            setOpenPopup={setOpenDetails}
          >
            <MessageDetails setOpenPopup={setOpenDetails} details={details} />
          </CustomDialog>
        </div>
      </div>
    </>
  )
}
