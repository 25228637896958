import React from 'react'

export const PlaceDetails = ({ details}) => {

  return (
    <div className="large-group">
      <div className="mini-group">
        <h3>Nombre: </h3>
        <label>{details.nombre}</label>
      </div>
      <div className="mini-group">
        <h3>Zona: </h3>
        <label>{details.zona}</label>
      </div>
      <div className="mini-group">
        <h3>Distancia: </h3>
        <label>{details.distancia} Kilometros</label>
      </div>
      <div className="mini-group">
        <h3>Camino: </h3>
        <label>{details.camino}</label>
      </div>
      <div className="small-group">
        <h3>Tiempo Estimado Camioneta Doble: </h3>
        <label>{details.tiempoDoble} Horas</label>
      </div>
      <div className="small-group">
        <h3>Tiempo Estimado Camión: </h3>
        <label>{details.tiempoCamion} Horas</label>
      </div>
      <div className="small-group">
        <h3>Tiempo Estimado Torton: </h3>
        <label>{details.tiempoTorton} Horas</label>
      </div>
      <div className="special-group">
        <h3>Observaciones: </h3>
        <label>{details.observaciones}</label>
      </div>
    </div>
  )
}
