import { getDrivers } from '../services/drivers'

export const selectDrivers = () => {
  const drivers = getDrivers()

  const driversList = []

  drivers.forEach((item) => {
    const driver = { id: item.nombre, title: item.nombre }

    driversList.push(driver)
  })

  return driversList
}
