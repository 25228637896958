import React, { useEffect, useLayoutEffect, useState } from 'react'

import { useForm, Form } from '../UI/Form'

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { Box } from '@mui/material'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'

import Swal from 'sweetalert2'
import { weeklyDeliveries } from '../../services/reports'
import Controls from '../UI/Controls'
import { selectDrivers } from '../../helpers/driversFuncions'
import { getDrivers } from '../../services/drivers'

const initialValues = { chofer: '', horas: '' }

export const CounterForm = ({ setOpenPopup }) => {
  const [date1, setDate1] = useState(new Date())
  const [date2, setDate2] = useState('')

  const { values, handleInputChange } = useForm(initialValues)

  const { chofer, horas } = values

  let drivers = selectDrivers()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (date2 !== '') {
      weeklyDeliveries(date1, date2, chofer, horas)
      setOpenPopup(false)
      setDate1(new Date())
      setDate2('')
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Debes de llenar todos los campos',
        customClass: {
          container: 'my-swal',
        },
        icon: 'info',
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="large-group">
        <div className="medium-group">
          <label>Chofer</label>
          <Controls.Select
            id="chofer"
            type="text"
            name="chofer"
            value={chofer}
            onChange={handleInputChange}
            options={drivers}
          />
        </div>
        <div className="medium-group">
          <label>Horas Extra</label>
          <Controls.Input
            id="horas"
            type="text"
            name="horas"
            value={horas}
            onChange={handleInputChange}
          />
        </div>
        <div className="medium-group">
          <label>Fecha inicial</label>

          <LocalizationProvider
            adapterLocale={esLocale}
            dateAdapter={DateFnsUtils}
          >
            <MobileDatePicker
              inputFormat="dd/MM/yyyy"
              value={date1}
              onChange={(date) => setDate1(date)}
              renderInput={({ inputRef, inputProps }) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <div className="medium-group" style={{ width: '100%' }}>
                    <input
                      className="input-select"
                      ref={inputRef}
                      {...inputProps}
                    />
                  </div>
                </Box>
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="medium-group">
          <label>Fecha final</label>

          <LocalizationProvider
            adapterLocale={esLocale}
            dateAdapter={DateFnsUtils}
          >
            <MobileDatePicker
              inputFormat="dd/MM/yyyy"
              value={date2}
              onChange={(date) => {
                if (date.getTime() < date1.getTime()) {
                  Swal.fire({
                    title: 'Error',
                    text: 'La fecha final no puede ser menor a la fecha inicial',
                    customClass: {
                      container: 'my-swal',
                    },
                    icon: 'info',
                  })
                } else {
                  setDate2(date)
                }
              }}
              renderInput={({ inputRef, inputProps }) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <div className="medium-group" style={{ width: '100%' }}>
                    <input
                      className="input-select"
                      ref={inputRef}
                      {...inputProps}
                    />
                  </div>
                </Box>
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="special-group">
          <button className="btn" onClick={handleSubmit}>
            Generar Reporte
          </button>
        </div>
      </div>
    </Form>
  )
}
