import { useState, useLayoutEffect } from 'react'
import { onValue, ref, getDatabase, set } from 'firebase/database'
import { database } from '../configs/firebase'

export const costosValues = {
  hhChofer: '',
  hhAyudante: '',
  infonavit: '',
  imss: '',
  comKenworthAyudante: '',
  com10tonAyudante: '',
  com3tonAyudante: '',
  comKenworth: '',
  com10ton: '',
  com3ton: '',
  com1ton: '',
  dKenworth: '',
  dInter: '',
  dFord: '',
  gFord: '',
  gNissan: '',
  neuKen: '',
  neu10ton: '',
  neu3ton: '',
  neu1ton: '',
  diesel: '',
  gasolina: '',
  camino: '',
  manInter: '',
  manFord: '',
  manFord2014: '',
  manKenworthD1: '',
  manKenworthD2: '',
  manKenworthD3: '',
  manNissan: '',
  refrendo: '',
  utilidad: '',
  utilidadAjustada: '',
  margen: '',
}

export const useCost = () => {
  const [cost, setCost] = useState([])

  useLayoutEffect(() => {
    onValue(
      ref(database, 'costos/'),
      (snapshot) => {
        const costList = []
        snapshot.forEach((item) => {
          const costItem = {
            id: item.key,
            concepto: item.val().concepto,
            costoAct: item.val().costoAct,
            tipo: item.val().tipo,
            valor: item.val().valor,
          }
          costList.push(costItem)

          switch (item.val().concepto) {
            case 'Camino Accidentado':
              costosValues.camino = item.val().costoAct
              break
            case 'Comision Camion Ayudante':
              costosValues.com10tonAyudante = item.val().costoAct
              break
            case 'Comision Camioneta Doble Ayudante':
              costosValues.com3tonAyudante = item.val().costoAct
              break
            case 'Comision Kenworth Ayudante':
              costosValues.comKenworthAyudante = item.val().costoAct
              break
            case 'Comision Camion Chofer':
              costosValues.com10ton = item.val().costoAct
              break
            case 'Comision Camioneta Doble Chofer':
              costosValues.com3ton = item.val().costoAct
              break
            case 'Comision Kenworth Chofer':
              costosValues.comKenworth = item.val().costoAct
              break
            case 'Comision Nissan Chofer':
              costosValues.com1ton = item.val().costoAct
              break
            case 'Diesel':
              costosValues.diesel = item.val().costoAct
              break
            case 'Diesel Ford':
              costosValues.dFord = item.val().valor
              break
            case 'Diesel Inter':
              costosValues.dInter = item.val().valor
              break
            case 'Diesel Kenworth':
              costosValues.dKenworth = item.val().valor
              break
            case 'Gasolina':
              costosValues.gasolina = item.val().costoAct
              break
            case 'Gasolina Ford 2014':
              costosValues.gFord = item.val().valor
              break
            case 'Gasolina Nissan':
              costosValues.gNissan = item.val().valor
              break
            case 'Hora Hombre Ayudante':
              costosValues.hhAyudante = item.val().costoAct
              break
            case 'Hora Hombre Chofer':
              costosValues.hhChofer = item.val().costoAct
              break
            case 'Infonavit':
              costosValues.infonavit = item.val().costoAct
              break
            case 'Mantenimiento Ford':
              costosValues.manFord = item.val().costoAct
              break
            case 'Mantenimiento Ford 2014':
              costosValues.manFord2014 = item.val().costoAct
              break
            case 'Mantenimiento Inter':
              costosValues.manInter = item.val().costoAct
              break
            case 'Mantenimiento Kenworth Distancia 1':
              costosValues.manKenworthD1 = item.val().costoAct
              break
            case 'Mantenimiento Kenworth Distancia 2':
              costosValues.manKenworthD2 = item.val().costoAct
              break
            case 'Mantenimiento Kenworth Distancia 3':
              costosValues.manKenworthD3 = item.val().costoAct
              break
            case 'Mantenimiento Nissan':
              costosValues.manNissan = item.val().costoAct
              break
            case 'Neumaticos Camion':
              costosValues.neu10ton = item.val().costoAct
              break
            case 'Neumaticos Camioneta Doble':
              costosValues.neu3ton = item.val().costoAct
              break
            case 'Neumaticos Nissan':
              costosValues.neu1ton = item.val().costoAct
              break
            case 'Neumaticos Kenworth':
              costosValues.neuKen = item.val().costoAct
              break
            case 'Refrendo':
              costosValues.refrendo = item.val().costoAct
              break
            case 'Seguro Social':
              costosValues.imss = item.val().costoAct
              break
            case 'Utilidad':
              costosValues.utilidad = item.val().valor
              break
            case 'Utilidad Ajustada':
              costosValues.utilidadAjustada = item.val().valor
              break
            case 'Margen':
              costosValues.margen = item.val().costoAct
              break
            default:
              console.log('error')
          }
        })
        setCost(costList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])
  return [cost]
}

export const updateCost = (id, concepto, costoAct, tipo, valor) => {
  const db = getDatabase()

  set(ref(db, `costos/${id}`), {
    concepto: concepto,
    costoAct: costoAct,
    tipo: tipo,
    valor: valor,
  })
}
