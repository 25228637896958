import React, { useLayoutEffect, useState } from 'react'

import { DeliveriesForm } from './form'
import { CustomDialog } from '../UI/Dialog'

import app, { auth } from '../../configs/firebase-config'

import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Toolbar, Tooltip, IconButton, Button } from '@mui/material'
import {
  Add,
  Search,
  Edit,
  Visibility,
  Delete,
  Close,
  OpenInBrowser,
  Summarize,
} from '@mui/icons-material'

import Swal from 'sweetalert2'
import { Box, makeStyles } from '@material-ui/core'
import { ClientsSelect } from './client'
import { deleteDeliveries } from '../../services/deliveries'
import { DeliverieDetails } from './details'
import Controls from '../UI/Controls'
import { CounterForm } from './counter'
import { filterDeliveries } from '../../helpers/deliveriesFunctions'
import { format } from 'date-fns'
import { getDrivers } from '../../services/drivers'

const useStyles = makeStyles(() => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  '@media (max-width: 700px)': {
    toolBar: {
      display: 'list-item',
      flexWrap: 'wrap',
    },
    margin: {
      marginTop: '20px',
    },
  },
}))

export const Deliveries = () => {
  var [deliveriesObject, setdeliveriesObject] = useState({})
  let [complementObject, setComplementObject] = useState([])

  const [search, setSearch] = useState('')
  const [indice, setIndice] = useState('')

  const [open, setOpen] = useState(false)
  const [openComplement, setOpenComplement] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [openClient, setOpenClient] = useState(false)

  const [update, setUpdate] = useState({})
  const [currentId, setCurrentId] = useState('')
  const [details, setDetails] = useState({})

  const [deliveries, setDeliveries] = useState(false)

  const [flag, setFlag] = useState(false)

  const [disabled, setDisabled] = useState(true)

  const [date, setDate] = useState(new Date())

  const classes = useStyles()

  useLayoutEffect(() => {
    getDrivers()
    var fechaFiltro = fecha()
    dateFormat()
    app
      .child('entregas')
      .orderByChild('fecha')
      .equalTo(fechaFiltro)
      .on('value', (snapshot) => {
        if (snapshot.val() != null) {
          setdeliveriesObject({
            ...snapshot.val(),
          })
        } else {
          setdeliveriesObject({})
        }
      })

    app
      .child('entregas')
      .orderByChild('date')
      .on('value', (snapshot) => {
        if (snapshot.val() != null) {
          const list = []
          snapshot.forEach((item) => {
            const itemList = {
              id: item.key,
              ...item.val(),
            }
            list.push(itemList)
          })
          setComplementObject(filterDeliveries(list))
        } else {
          //setdeliveriesObject({})
        }
      })

    if (
      auth.currentUser.email === 'diegofs117@outlook.com' ||
      auth.currentUser.email === 'mat.preconsa_mauricio@hotmail.com' ||
      auth.currentUser.email === 'mat.preconsa_mostrador@outlook.es' ||
      auth.currentUser.email === 'mat.preconsa_operaciones@outlook.com'
    ) {
      setDisabled(false)
    }
  }, [setDisabled])

  const searchData = () => {
    if (indice === 'fecha') {
      var date =
        search.getDate() +
        '/' +
        (search.getMonth() + 1) +
        '/' +
        search.getFullYear()
      app
        .child('entregas')
        .orderByChild(indice)
        .equalTo(date)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            deliveriesObject = snapshot.val()
            setdeliveriesObject(deliveriesObject)
          } else {
            setdeliveriesObject({})
          }
        })
    } else {
      app
        .child('entregas')
        .orderByChild(indice)
        .equalTo(search)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            deliveriesObject = snapshot.val()
            setdeliveriesObject(deliveriesObject)
          } else {
            setdeliveriesObject({})
          }
        })
    }

    setSearch('')
  }

  const resetData = () => {
    var fechaFiltro = fecha()
    app
      .child('entregas')
      .orderByChild('fecha')
      .equalTo(fechaFiltro)
      .on('value', (snapshot) => {
        if (snapshot.val() != null) {
          setdeliveriesObject({
            ...snapshot.val(),
          })
        } else {
          setdeliveriesObject({})
        }
      })
  }

  const fecha = () => {
    var fechaAct = ''

    var day = new Date().getDate()
    var month = new Date().getMonth() + 1
    var year = new Date().getFullYear()

    fechaAct = day + '/' + month + '/' + year
    return fechaAct
  }

  const alertColor = (estado) => {
    if (estado === 'En Tránsito') {
      return 'gold'
    } else if (estado === 'Próximo a enviar') {
      return 'lightgreen'
    } else if (estado === 'Envío Completado') {
      return 'lightblue'
    }
  }

  const dateFormat = () => {
    if (search === null) {
      //console.log('2')
      return format(new Date(), 'PPP')
    } else {
      return search
    }
  }

  return (
    <>
      <div>
        <div style={{ marginTop: '3%' }}>
          <h2 className="h1">Entregas</h2>
        </div>
        <div className="form" style={{ marginBottom: '30px' }}>
          <Toolbar className={classes.toolBar}>
            <div
              className="small-group"
              style={{
                marginBottom: '22.5px',
              }}
            >
              <Controls.Select
                id="index"
                name="index"
                value={indice}
                onChange={(e) => setIndice(e.target.value)}
                options={[
                  { id: 'cliente', title: 'Buscar por Cliente' },
                  { id: 'fecha', title: 'Buscar por Fecha' },
                ]}
              />
            </div>
            <div
              className="small-group"
              style={{
                marginBottom: '22.5px',
              }}
            ></div>
            <div
              className="small-group"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '22.5px',
              }}
            >
              {indice === 'cliente' && (
                <div style={{ display: 'flex', alignContent: 'center' }}>
                  <div style={{ width: '100%' }}>
                    <input
                      className="input-select"
                      id="search"
                      name="search"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      placeholder="Cliente"
                      disabled
                    />
                  </div>
                  <div
                    style={{
                      width: '20%',
                      marginLeft: '5px',
                    }}
                  >
                    <Tooltip title="Seleccionar Cliente">
                      <IconButton
                        onClick={() => {
                          setOpenClient(true)
                        }}
                        sx={{ width: '2rem', height: '2rem' }}
                      >
                        <OpenInBrowser />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      width: '20%',
                      marginLeft: '5px',
                    }}
                  >
                    <Tooltip title="Buscar Pedidos">
                      <IconButton
                        onClick={() => {
                          searchData()
                        }}
                        sx={{ width: '2rem', height: '2rem' }}
                      >
                        <Search />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      width: '20%',
                      marginLeft: '5px',
                    }}
                  >
                    <Tooltip title="Reiniciar Busqueda">
                      <IconButton
                        onClick={() => {
                          setSearch('')
                          resetData()
                        }}
                        sx={{ width: '2rem', height: '2rem' }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
              {indice === 'fecha' && (
                <div style={{ display: 'flex', alignContent: 'center' }}>
                  <div style={{ width: '100%' }}>
                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                      <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        value={search || null}
                        onChange={(date) => setSearch(date)}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <div
                              className="medium-group"
                              style={{ width: '77.5%' }}
                            >
                              <input
                                className="input-select"
                                ref={inputRef}
                                {...inputProps}
                                disabled
                              />
                            </div>
                            <Tooltip title="Seleccionar Fecha">
                              <div style={{ marginTop: '-20px' }}>
                                {InputProps?.endAdornment}
                              </div>
                            </Tooltip>
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div
                    style={{
                      width: '20%',
                      marginLeft: '5px',
                    }}
                  >
                    <Tooltip title="Buscar Pedidos">
                      <IconButton
                        onClick={() => {
                          searchData()
                        }}
                        sx={{ width: '2rem', height: '2rem' }}
                      >
                        <Search />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      width: '20%',
                      //marginLeft: '5px',
                    }}
                  >
                    <Tooltip title="Reiniciar Busqueda">
                      <IconButton
                        onClick={() => {
                          setSearch('')
                          resetData()
                        }}
                        sx={{ width: '2rem', height: '2rem' }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>

            <div
              className="small-group"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '22.5px',
              }}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                sx={{
                  backgroundColor: '#009696',
                  padding: '12px 15px',
                  height: '40px',
                  fontSize: '1em;',
                  border: '1px solid #00544e',
                  borderRadius: '30px',
                  transition: '0.3s all ease',
                  color: 'secondary',
                  marginBottom: '20px',

                  '&:hover': {
                    backgroundColor: '#00544e',
                    border: '1px solid #009696',
                  },
                  '@media (max-width: 700px)': {
                    //marginTop: '20px',
                    marginBottom: '20px',
                  },
                }}
                disabled={disabled}
                onClick={() => {
                  setOpen(true)
                  setFlag(false)
                  setUpdate({})
                  setCurrentId('')
                }}
              >
                Agregar Entrega
              </Button>
            </div>
            <div
              className="small-group"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '22.5px',
              }}
            ></div>
            <div
              className="small-group"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="contained"
                startIcon={<Summarize />}
                sx={{
                  backgroundColor: '#009696',
                  padding: '12px 15px',
                  height: '40px',
                  fontSize: '1em;',
                  border: '1px solid #00544e',
                  borderRadius: '30px',
                  transition: '0.3s all ease',
                  color: 'secondary',
                  '&:hover': {
                    backgroundColor: '#00544e',
                    border: '1px solid #009696',
                  },
                  '@media (max-width: 700px)': {
                    //marginTop: '20px',
                    marginBottom: '20px',
                  },
                }}
                onClick={() => {
                  setDeliveries(true)
                }}
              >
                Reporte Semanal
              </Button>
            </div>
          </Toolbar>
          <div className="special-group">
            <h3 style={{ justifyContent: 'center', display: 'flex' }}>
              **Favor de llenar los horarios en la fecha en que se realizará el
              envío**
            </h3>
          </div>
          <table
            style={{
              border: '1px solid #ccc',
              borderCollapse: 'collapse',
              margin: '0',
              padding: '0',
              width: '100%',
              tableLayout: 'fixed',
              color: '#00544e',
            }}
          >
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Producto</th>
                <th>Lugar</th>
                <th>Inicio de Carga</th>
                <th>Salida de Planta</th>
                <th>Llegada a Planta</th>
                <th>Tiempo Estimado de Regreso</th>
                <th>Chofer</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                >
                  Recogen
                </td>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                >
                  en Fabrica
                </td>
              </tr>
            </tbody>
            <tbody>
              {Object.keys(deliveriesObject).map((id) => {
                if (
                  deliveriesObject[id].unidad === 'Recogen (Camioneta)' ||
                  deliveriesObject[id].unidad === 'Recogen (Camion)' ||
                  deliveriesObject[id].unidad === 'Recogen (Torton)'
                ) {
                  return (
                    <tr
                      key={id}
                      style={{
                        backgroundColor: alertColor(
                          deliveriesObject[id].estado
                        ),
                      }}
                    >
                      <td
                        data-label="Fecha"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].fecha}
                        {/*new Date(deliveriesObject[id].date).toISOString()*/}
                      </td>
                      <td
                        data-label="Cliente"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].cliente}
                      </td>
                      <td
                        data-label="Producto"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].producto}
                      </td>
                      <td
                        data-label="Lugar"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].lugar}
                      </td>
                      <td
                        data-label="Inicio de Carga"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrInicio}
                      </td>
                      <td
                        data-label="Salida de Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrSalida}
                      </td>
                      <td
                        data-label="Llegada a Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrEntrada}
                      </td>
                      <td
                        data-label="Tiempo estimado de regreso"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].tiempo}
                      </td>
                      <td
                        data-label="Chofer"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].chofer}
                      </td>

                      <td
                        data-label="Acciones"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            setUpdate(deliveriesObject[id])
                            setCurrentId(id)
                            setFlag(true)
                            setOpen(true)
                          }}
                          sx={{
                            backgroundColor: 'orange',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkorange',
                              border: '1px solid orange',
                            },
                          }}
                        >
                          <Edit sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            Swal.fire({
                              title: '¿Estas seguro de eliminar este registro?',
                              showDenyButton: true,
                              showCancelButton: false,
                              confirmButtonText: 'Sí',
                              denyButtonText: 'No',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteDeliveries(id)
                                Swal.fire(
                                  'Hecho',
                                  'Registro eliminado exitosamente',
                                  'success'
                                )
                              } else if (result.isDenied) {
                                Swal.fire('Operación abortada', '', 'info')
                              }
                            })
                          }}
                          sx={{
                            backgroundColor: 'red',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkred',
                              border: '1px solid red',
                            },
                          }}
                        >
                          <Delete sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setDetails(deliveriesObject[id])
                            setFlag(true)
                            setOpenDetails(true)
                          }}
                          sx={{
                            backgroundColor: '#00544e',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            '&:hover': {
                              backgroundColor: '#009696',
                              border: '1px solid #00544e',
                            },
                          }}
                        >
                          <Visibility sx={{ width: '20px', height: '20px' }} />
                        </IconButton>
                      </td>
                    </tr>
                  )
                }
                return null
              })}
            </tbody>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                >
                  Kenworth
                </td>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                />
              </tr>
            </tbody>
            <tbody>
              {Object.keys(deliveriesObject).map((id) => {
                if (deliveriesObject[id].unidad === 'Kenworth') {
                  return (
                    <tr
                      key={id}
                      style={{
                        backgroundColor: alertColor(
                          deliveriesObject[id].estado
                        ),
                      }}
                    >
                      <td
                        data-label="Fecha"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].fecha}
                      </td>
                      <td
                        data-label="Cliente"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].cliente}
                      </td>
                      <td
                        data-label="Producto"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].producto}
                      </td>
                      <td
                        data-label="Lugar"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].lugar}
                      </td>

                      <td
                        data-label="Inicio de Carga"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrInicio}
                      </td>
                      <td
                        data-label="Salida de Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrSalida}
                      </td>
                      <td
                        data-label="Llegada a Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrEntrada}
                      </td>
                      <td
                        data-label="Tiempo estimado de regreso"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].tiempo}
                      </td>
                      <td
                        data-label="Chofer"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].chofer}
                      </td>

                      <td
                        data-label="Acciones"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            setUpdate(deliveriesObject[id])
                            setCurrentId(id)
                            setFlag(true)
                            setOpen(true)
                          }}
                          sx={{
                            backgroundColor: 'orange',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkorange',
                              border: '1px solid orange',
                            },
                          }}
                        >
                          <Edit sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            Swal.fire({
                              title: '¿Estas seguro de eliminar este registro?',
                              showDenyButton: true,
                              showCancelButton: false,
                              confirmButtonText: 'Sí',
                              denyButtonText: 'No',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteDeliveries(id)
                                Swal.fire(
                                  'Hecho',
                                  'Registro eliminado exitosamente',
                                  'success'
                                )
                              } else if (result.isDenied) {
                                Swal.fire('Operación abortada', '', 'info')
                              }
                            })
                          }}
                          sx={{
                            backgroundColor: 'red',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkred',
                              border: '1px solid red',
                            },
                          }}
                        >
                          <Delete sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setDetails(deliveriesObject[id])
                            setFlag(true)
                            setOpenDetails(true)
                          }}
                          sx={{
                            backgroundColor: '#00544e',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            '&:hover': {
                              backgroundColor: '#009696',
                              border: '1px solid #00544e',
                            },
                          }}
                        >
                          <Visibility sx={{ width: '20px', height: '20px' }} />
                        </IconButton>
                      </td>
                    </tr>
                  )
                }
                return null
              })}
            </tbody>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                >
                  International
                </td>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                />
              </tr>
            </tbody>
            <tbody>
              {Object.keys(deliveriesObject).map((id) => {
                if (deliveriesObject[id].unidad === 'International') {
                  return (
                    <tr
                      key={id}
                      style={{
                        backgroundColor: alertColor(
                          deliveriesObject[id].estado
                        ),
                      }}
                    >
                      <td
                        data-label="Fecha"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].fecha}
                      </td>
                      <td
                        data-label="Cliente"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].cliente}
                      </td>
                      <td
                        data-label="Producto"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].producto}
                      </td>
                      <td
                        data-label="Lugar"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].lugar}
                      </td>

                      <td
                        data-label="Inicio de Carga"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrInicio}
                      </td>
                      <td
                        data-label="Salida de Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrSalida}
                      </td>
                      <td
                        data-label="Llegada a Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrEntrada}
                      </td>
                      <td
                        data-label="Tiempo estimado de regreso"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].tiempo}
                      </td>
                      <td
                        data-label="Chofer"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].chofer}
                      </td>

                      <td
                        data-label="Acciones"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            setUpdate(deliveriesObject[id])
                            setCurrentId(id)
                            setFlag(true)
                            setOpen(true)
                          }}
                          sx={{
                            backgroundColor: 'orange',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkorange',
                              border: '1px solid orange',
                            },
                          }}
                        >
                          <Edit sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            Swal.fire({
                              title: '¿Estas seguro de eliminar este registro?',
                              showDenyButton: true,
                              showCancelButton: false,
                              confirmButtonText: 'Sí',
                              denyButtonText: 'No',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteDeliveries(id)
                                Swal.fire(
                                  'Hecho',
                                  'Registro eliminado exitosamente',
                                  'success'
                                )
                              } else if (result.isDenied) {
                                Swal.fire('Operación abortada', '', 'info')
                              }
                            })
                          }}
                          sx={{
                            backgroundColor: 'red',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkred',
                              border: '1px solid red',
                            },
                          }}
                        >
                          <Delete sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setDetails(deliveriesObject[id])
                            setFlag(true)
                            setOpenDetails(true)
                          }}
                          sx={{
                            backgroundColor: '#00544e',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            '&:hover': {
                              backgroundColor: '#009696',
                              border: '1px solid #00544e',
                            },
                          }}
                        >
                          <Visibility sx={{ width: '20px', height: '20px' }} />
                        </IconButton>
                      </td>
                    </tr>
                  )
                }
                return null
              })}
            </tbody>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                >
                  Camión Ford
                </td>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                />
              </tr>
            </tbody>
            <tbody>
              {Object.keys(deliveriesObject).map((id) => {
                if (deliveriesObject[id].unidad === 'Camión Ford') {
                  return (
                    <tr
                      key={id}
                      style={{
                        backgroundColor: alertColor(
                          deliveriesObject[id].estado
                        ),
                      }}
                    >
                      <td
                        data-label="Fecha"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].fecha}
                      </td>
                      <td
                        data-label="Cliente"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].cliente}
                      </td>
                      <td
                        data-label="Producto"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].producto}
                      </td>
                      <td
                        data-label="Lugar"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].lugar}
                      </td>

                      <td
                        data-label="Inicio de Carga"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrInicio}
                      </td>
                      <td
                        data-label="Salida de Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrSalida}
                      </td>
                      <td
                        data-label="Llegada a Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrEntrada}
                      </td>
                      <td
                        data-label="Tiempo estimado de regreso"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].tiempo}
                      </td>
                      <td
                        data-label="Chofer"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].chofer}
                      </td>

                      <td
                        data-label="Acciones"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            setUpdate(deliveriesObject[id])
                            setCurrentId(id)
                            setFlag(true)
                            setOpen(true)
                          }}
                          sx={{
                            backgroundColor: 'orange',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkorange',
                              border: '1px solid orange',
                            },
                          }}
                        >
                          <Edit sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            Swal.fire({
                              title: '¿Estas seguro de eliminar este registro?',
                              showDenyButton: true,
                              showCancelButton: false,
                              confirmButtonText: 'Sí',
                              denyButtonText: 'No',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteDeliveries(id)
                                Swal.fire(
                                  'Hecho',
                                  'Registro eliminado exitosamente',
                                  'success'
                                )
                              } else if (result.isDenied) {
                                Swal.fire('Operación abortada', '', 'info')
                              }
                            })
                          }}
                          sx={{
                            backgroundColor: 'red',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkred',
                              border: '1px solid red',
                            },
                          }}
                        >
                          <Delete sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setDetails(deliveriesObject[id])
                            setFlag(true)
                            setOpenDetails(true)
                          }}
                          sx={{
                            backgroundColor: '#00544e',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            '&:hover': {
                              backgroundColor: '#009696',
                              border: '1px solid #00544e',
                            },
                          }}
                        >
                          <Visibility sx={{ width: '20px', height: '20px' }} />
                        </IconButton>
                      </td>
                    </tr>
                  )
                }
                return null
              })}
            </tbody>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                >
                  Ford 2014
                </td>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                />
              </tr>
            </tbody>
            <tbody>
              {Object.keys(deliveriesObject).map((id) => {
                if (deliveriesObject[id].unidad === 'Camioneta Ford') {
                  return (
                    <tr
                      key={id}
                      style={{
                        backgroundColor: alertColor(
                          deliveriesObject[id].estado
                        ),
                      }}
                    >
                      <td
                        data-label="Fecha"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].fecha}
                      </td>
                      <td
                        data-label="Cliente"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].cliente}
                      </td>
                      <td
                        data-label="Producto"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].producto}
                      </td>
                      <td
                        data-label="Lugar"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].lugar}
                      </td>

                      <td
                        data-label="Inicio de Carga"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrInicio}
                      </td>
                      <td
                        data-label="Salida de Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrSalida}
                      </td>
                      <td
                        data-label="Llegada a Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrEntrada}
                      </td>
                      <td
                        data-label="Tiempo estimado de regreso"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].tiempo}
                      </td>
                      <td
                        data-label="Chofer"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].chofer}
                      </td>

                      <td
                        data-label="Acciones"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            setUpdate(deliveriesObject[id])
                            setCurrentId(id)
                            setFlag(true)
                            setOpen(true)
                          }}
                          sx={{
                            backgroundColor: 'orange',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkorange',
                              border: '1px solid orange',
                            },
                          }}
                        >
                          <Edit sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            Swal.fire({
                              title: '¿Estas seguro de eliminar este registro?',
                              showDenyButton: true,
                              showCancelButton: false,
                              confirmButtonText: 'Sí',
                              denyButtonText: 'No',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteDeliveries(id)
                                Swal.fire(
                                  'Hecho',
                                  'Registro eliminado exitosamente',
                                  'success'
                                )
                              } else if (result.isDenied) {
                                Swal.fire('Operación abortada', '', 'info')
                              }
                            })
                          }}
                          sx={{
                            backgroundColor: 'red',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkred',
                              border: '1px solid red',
                            },
                          }}
                        >
                          <Delete sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setDetails(deliveriesObject[id])
                            setFlag(true)
                            setOpenDetails(true)
                          }}
                          sx={{
                            backgroundColor: '#00544e',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            '&:hover': {
                              backgroundColor: '#009696',
                              border: '1px solid #00544e',
                            },
                          }}
                        >
                          <Visibility sx={{ width: '20px', height: '20px' }} />
                        </IconButton>
                      </td>
                    </tr>
                  )
                }
                return null
              })}
            </tbody>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                >
                  Nissan 2014
                </td>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#00544e',
                    color: '#DEC65E',
                  }}
                />
              </tr>
            </tbody>
            <tbody>
              {Object.keys(deliveriesObject).map((id) => {
                if (deliveriesObject[id].unidad === 'Nissan 2014') {
                  return (
                    <tr
                      key={id}
                      style={{
                        backgroundColor: alertColor(
                          deliveriesObject[id].estado
                        ),
                      }}
                    >
                      <td
                        data-label="Fecha"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].fecha}
                      </td>
                      <td
                        data-label="Cliente"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].cliente}
                      </td>
                      <td
                        data-label="Producto"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].producto}
                      </td>
                      <td
                        data-label="Lugar"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].lugar}
                      </td>

                      <td
                        data-label="Inicio de Carga"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrInicio}
                      </td>
                      <td
                        data-label="Salida de Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrSalida}
                      </td>
                      <td
                        data-label="Llegada a Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].hrEntrada}
                      </td>
                      <td
                        data-label="Tiempo estimado de regreso"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].tiempo}
                      </td>
                      <td
                        data-label="Chofer"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {deliveriesObject[id].chofer}
                      </td>

                      <td
                        data-label="Acciones"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            setUpdate(deliveriesObject[id])
                            setCurrentId(id)
                            setFlag(true)
                            setOpen(true)
                          }}
                          sx={{
                            backgroundColor: 'orange',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkorange',
                              border: '1px solid orange',
                            },
                          }}
                        >
                          <Edit sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            Swal.fire({
                              title: '¿Estas seguro de eliminar este registro?',
                              showDenyButton: true,
                              showCancelButton: false,
                              confirmButtonText: 'Sí',
                              denyButtonText: 'No',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteDeliveries(id)
                                Swal.fire(
                                  'Hecho',
                                  'Registro eliminado exitosamente',
                                  'success'
                                )
                              } else if (result.isDenied) {
                                Swal.fire('Operación abortada', '', 'info')
                              }
                            })
                          }}
                          sx={{
                            backgroundColor: 'red',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkred',
                              border: '1px solid red',
                            },
                          }}
                        >
                          <Delete sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setDetails(deliveriesObject[id])
                            setFlag(true)
                            setOpenDetails(true)
                          }}
                          sx={{
                            backgroundColor: '#00544e',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            '&:hover': {
                              backgroundColor: '#009696',
                              border: '1px solid #00544e',
                            },
                          }}
                        >
                          <Visibility sx={{ width: '20px', height: '20px' }} />
                        </IconButton>
                      </td>
                    </tr>
                  )
                }
                return null
              })}
            </tbody>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#FF6C37',
                    color: 'white',
                  }}
                >
                  Entregas Pendientes
                </td>
                <td
                  style={{
                    padding: '0px',
                    backgroundColor: '#FF6C37',
                    color: 'white',
                  }}
                />
              </tr>
            </tbody>
            <tbody>
              {Object.keys(complementObject).map((id) => {
                if (complementObject[id].estado !== 'Envío Completado') {
                  return (
                    <tr
                      key={id}
                      style={{
                        backgroundColor: alertColor(
                          complementObject[id].estado
                        ),
                      }}
                    >
                      <td
                        data-label="Fecha"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {complementObject[id].fecha}
                      </td>
                      <td
                        data-label="Cliente"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {complementObject[id].cliente}
                      </td>
                      <td
                        data-label="Producto"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {complementObject[id].producto}
                      </td>
                      <td
                        data-label="Lugar"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {complementObject[id].lugar}
                      </td>

                      <td
                        data-label="Inicio de Carga"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {complementObject[id].hrInicio}
                      </td>
                      <td
                        data-label="Salida de Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {complementObject[id].hrSalida}
                      </td>
                      <td
                        data-label="Llegada a Planta"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {complementObject[id].hrEntrada}
                      </td>
                      <td
                        data-label="Tiempo estimado de regreso"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {complementObject[id].tiempo}
                      </td>
                      <td
                        data-label="Chofer"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                      >
                        {complementObject[id].chofer}
                      </td>

                      <td
                        data-label="Acciones"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <IconButton
                          disabled={true}
                          onClick={() => {
                            setUpdate(complementObject[id])
                            setCurrentId(id)
                            setFlag(true)
                            setOpen(true)
                          }}
                          sx={{
                            backgroundColor: 'orange',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkorange',
                              border: '1px solid orange',
                            },
                          }}
                        >
                          <Edit sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          disabled={true}
                          onClick={() => {
                            Swal.fire({
                              title: '¿Estas seguro de eliminar este registro?',
                              showDenyButton: true,
                              showCancelButton: false,
                              confirmButtonText: 'Sí',
                              denyButtonText: 'No',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteDeliveries(id)
                                Swal.fire(
                                  'Hecho',
                                  'Registro eliminado exitosamente',
                                  'success'
                                )
                              } else if (result.isDenied) {
                                Swal.fire('Operación abortada', '', 'info')
                              }
                            })
                          }}
                          sx={{
                            backgroundColor: 'red',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            marginRight: '5px',
                            '&:hover': {
                              backgroundColor: 'darkred',
                              border: '1px solid red',
                            },
                          }}
                        >
                          <Delete sx={{ width: '20px', height: '20px' }} />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setDetails(complementObject[id])
                            setFlag(true)
                            setOpenDetails(true)
                          }}
                          sx={{
                            backgroundColor: '#00544e',
                            color: 'white',
                            height: '30px',
                            width: '30px',
                            transition: '0.3s all ease',
                            '&:hover': {
                              backgroundColor: '#009696',
                              border: '1px solid #00544e',
                            },
                          }}
                        >
                          <Visibility sx={{ width: '20px', height: '20px' }} />
                        </IconButton>
                      </td>
                    </tr>
                  )
                } else {
                  return false
                }
              })}
            </tbody>
          </table>
          <CustomDialog
            title="Formulario Pedidos"
            openPopup={open}
            setOpenPopup={setOpen}
          >
            <DeliveriesForm
              setOpenPopup={setOpen}
              flag={flag}
              setFlag={setFlag}
              update={update}
              id={currentId}
              setCurrentId={setCurrentId}
            />
          </CustomDialog>
          <CustomDialog
            title="Clientes"
            openPopup={openClient}
            setOpenPopup={setOpenClient}
          >
            <ClientsSelect setOpenPopup={setOpenClient} setClient={setSearch} />
          </CustomDialog>
          <CustomDialog
            title="Detalles"
            openPopup={openDetails}
            setOpenPopup={setOpenDetails}
          >
            <DeliverieDetails details={details} />
          </CustomDialog>
          <CustomDialog
            title="Reporte de Viajes"
            openPopup={deliveries}
            setOpenPopup={setDeliveries}
          >
            <CounterForm setOpenPopup={setDeliveries} />
          </CustomDialog>
        </div>
      </div>
    </>
  )
}
